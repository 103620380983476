import { Component, OnInit, ViewEncapsulation, Input, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { IValidator, IValidatorType } from '../type/iValidator';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'elm-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ElmSelectComponent, multi: true }],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElmSelectComponent implements IValidator, OnInit, AfterViewInit, OnChanges, ControlValueAccessor {


  valid = true;
  focus = false;

  @Input() appearance: 'legacy' | 'outline' | 'standard' | 'fill' = 'legacy';
  @Input() icon_clear = true;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  @Input() placeholder: string = '';
  @Input() prefix;
  @Input() suffix;
  @Input() multi;
  @Input() hint;
  @Input() options : {
    field_label?:string;
    error?:string;
  } = {error:'123'};
  @Input() tree = false;
  @Input() autocomplete = false;
  @Input() validator: IValidatorType = null;
  @Input() filter: Function;
  @Input() limit = 0;

  @Output('focus') evnFocus: EventEmitter<any> = new EventEmitter();
  @Output('blur') evnBlur: EventEmitter<any> = new EventEmitter();

  @Input() disabled = false;

  clar_not_match = false;

  value_autocomplete = '';
  @Input() source = null;

  source2 = [];



  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source) {
      if (!changes.source) {
        this.source2 = [];
      } else {
        if (Array.isArray(this.source)) {
          this.source2 = this.source;
        } else {
          this.source2 = [];
          for (const name in this.source) {

            this.source2.push({ id: name, label: this.source[name] });
          }
        }
      }
    }
  }




  constructor(private cd:ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  }

  get values() {
    let result = [];
    if (!this.innerValue || this.innerValue.length === 0) {
      return [];
    }
    if (typeof this.innerValue[0] === 'object') {
      return this.innerValue;
    }

    this.innerValue.forEach((id: any) => {
      if (this.source[id]) {
        result.push({
          id: id,
          label: this.source[id]
        });
      }
    });
    return result;


  }

  get filteredChoices() {
    let result = [];
    if (this.value_autocomplete && typeof this.value_autocomplete==='string') {
      const find = this.value_autocomplete.toLowerCase();
      const items = this.source2.filter((item: any) => {
        return (item[this.options.field_label || 'label'] + (item.sublabel ? item.sublabel.join(' ') : '')).toLowerCase().includes(find);
      });
      this.clar_not_match = items.length === 0 ? true : false;
      result = items;
    } else{
      result = this.source2;
    }
    if (this.filter) {
      result = result.filter((item:any)=>{
        return this.filter(item);
      })
    }
    return result;
  }
  getTree(choice: any) {
    let st = '';
    return st;
  }
  getValue(): string {

    return this.value;
  }

  onOptionSelected($event: any) {
    this.value = $event.option.value;
    this.value_autocomplete = this.value[this.options.field_label || 'label'];
  }

  displayFn(choice: any): string {
    if (!this.value || !this.source) {
      return '';
    }
    if (Array.isArray(this.source)) {
      return (this.value[this.options.field_label || 'label']) || '';
    }
    if (typeof this.value === 'object') {
      return (this.value[this.options.field_label || 'label']) || '';
    }

    return this.source[this.value] || '';
  }

  onFocus($event: any, focus: boolean) {
    this.focus = focus;
    if (focus) {
      this.evnFocus.emit(true);
$event.target.select();
    }
    else {
      this.value_autocomplete = null;
      this.evnBlur.emit(true);
    }
  }

  compareFn(option: any, value: any): boolean {

    const a = option.id;
    const b = value ? value.id || value || null : null;
    return a === b ? true : false;
  }



  /*=================== ngModel ===================*/
  innerValue: any;
  private onChangedCallBack = new Array<(value: any) => void>();
  private onTouchedCallBack = new Array<() => void>();

  get value(): any {

    return this.innerValue;
  }

  set value(value: any) {

    if (value !== this.innerValue) {
      
      // this.value_autocomplete = null;
      this.valid = true;
      this.innerValue = value;
      if (!this.source) {
        return ;
      }
      this.onChangedCallBack.forEach((f: any) => {
        if (!value) {
          return f(null);
        }
        f(Array.isArray(this.source) ? value : (value.id || null))
        if (Array.isArray(this.source)) {
          return f(this.value);
        } else if (!this.multi) {
          return f(value.id || value || null);
        }

        const items = [];
        value.forEach((a) => {
          items.push(a.id);
        })
        f(items);


      });

    }
  }


  writeValue(value: any): void {
    this.value_autocomplete = '';//TODO:проверить

    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChangedCallBack.push(fn);
  }

  registerOnTouched(fn: any): void {

    this.onTouchedCallBack.push(fn);
  }

  /*=================== /ngModel ===================*/

}

import { Component, OnInit, ViewChild, ContentChild, QueryList, AfterContentInit, ContentChildren, TemplateRef, OnDestroy } from '@angular/core';
import { ContainerService } from '@container';

@Component({
  selector: 'app-wrap',
  templateUrl: './wrap.component.html',
  styleUrls: ['./wrap.component.scss']
})
export class WrapComponent implements OnInit,OnDestroy {
  

  @ContentChildren('baseTitle', { descendants: true }) children:QueryList<TemplateRef<any>>;

  constructor(public cnt:ContainerService) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

}

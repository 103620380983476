import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { ContainerService } from '@container';
import { ActivatedRoute, Router } from '@angular/router';
import rbRegion from '@model/rbRegion';
import Hospital from '@model/Hospital';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['./hospitals.component.scss']
})
export class HospitalsComponent implements OnInit, OnDestroy, AfterViewInit {

  private alive = true;

  hospitals: Hospital[] = [];
  // all_regions: rbRegion[] = [];
  current_region: rbRegion = this.cnt.current_region;
  type: 'state' | 'commercial';
  // tslint:disable-next-line: variable-name
  open_region: rbRegion;
  open_all = false;
  _finder = '';
  preloader = false;
  region_count_hospital = {};

  timer_finder = null;
  set finder(value: string) {
    if (this.timer_finder) {
      clearTimeout(this.timer_finder);
    }
    this.timer_finder = setTimeout(() => {
      this.timer_finder = null;
      this.updateHospitals();
      this.cd.detectChanges();
    }, 250);
    this._finder = value;
  }
  get finder() {
    return this._finder;
  }

  constructor(public cnt: ContainerService, public activatedRoute: ActivatedRoute, private cd: ChangeDetectorRef, private router: Router, @Inject(PLATFORM_ID) private platformId) { }
  ngOnDestroy(): void {
    this.hospitals = [];
    this.current_region = null;
    this.open_region = null;
    this.region_count_hospital = null;

    this.alive = false;
  }
  ngOnInit(): void {

    this.open_all = this.platformId === 'browser' ? false : true;

    this.cnt.observable_region
      .pipe(takeWhile(() => this.alive))
      .subscribe((value: any) => {
        if (!value) {
          return;
        }
        const v = value ? value.id : null;
        const c = this.current_region ? this.current_region.id : null;
        if (v !== c) {
          return this.router.navigate(['hospitals', value.name]);
        }
      });

    // TODO: this.cd.detach();
    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params: { region_name: string; type: 'state' | 'commercial' }) => {

        this.type = null;
        this.current_region = null;

        if (params.region_name) {
          this.current_region = this.cnt.regions.find((region: rbRegion) => {
            return region.name === params.region_name;
          });
        } else if (this.cnt.current_region) {
          return this.router.navigate(['hospitals', this.cnt.current_region.name]);
        }

        if (this.current_region) {
          this.type = params.type || 'state';
          this.updateHospitals();



        }
        this.updateMeta();
        this.cd.detectChanges();

        // this.type = params.type || 'state';
        // if (params.region_name === 'state' || params.region_name === 'commercial') {
        //   this.type = params.region_name || 'state';
        // }
        // else if (params.region_name) {

        //   var params_region = this.cnt.regions.find((region) => {
        //     return region.name === params.region_name;
        //   });
        //   this.current_region = params_region;
        // }

        // if (!this.current_region) {
        //   this.all_regions = this.cnt.regions;
        //   this.type = null;
        //   return;
        // }





      });


  }

  updateMeta() {
    this.cnt.setTitle('').setDescription('').setKeyWords('');

  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }



  onClickOpenRegion(region: rbRegion) {
    if (this.open_all) {
      this.open_all = false;
    } else {
      this.open_region = this.open_region === region ? null : region;
    }
    this.open_all = false;
    this.cd.detectChanges();
  }

  updateHospitals() {
    const finder = this.finder ? this.finder.toLocaleLowerCase() : null;
    this.region_count_hospital = {};
    if (finder) {
      this.open_all = true;
      this.open_region = null;
    }
    if (this.type === 'state') {
      this.hospitals = this.cnt.hospitals.filter((hospital: Hospital) => {
        if (hospital.type !== 'state') {
          return false;
        }
        if (hospital.parent) {
          return false;
        }
        if (hospital.region && hospital.region.parent.id != this.current_region.id) {
          return false;
        }
        if (!finder) {
          if (!hospital.region?.id) {
            console.log(hospital);
          }
          this.region_count_hospital[hospital.region.id] = true;
          return true;
        }
        let f = (hospital.fullTitle + ' ' + hospital.address + ' ' + (hospital.child ? hospital.child.map((h: Hospital) => { return h.fullTitle + ' ' + h.address }).join(' ') : '')).toLocaleLowerCase();
        const result = f.includes(finder);
        if (result) {
          this.region_count_hospital[hospital.region.id] = true;
        }
        return result;

      });
    } else if (this.type === 'commercial') {
      this.hospitals = this.cnt.hospitals.filter((hospital: Hospital) => {
        if (hospital.type !== 'commercial') {
          return false;
        }
        if (!finder) {
          return true;
        }
        let f = (hospital.fullTitle + ' ' + hospital.address + ' ' + (hospital.child ? hospital.child.map((h: Hospital) => { return h.fullTitle + ' ' + h.address }).join(' ') : '')).toLocaleLowerCase();
        return f.includes(finder);

      });

    }
  }


}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContainerService } from '@container';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  form: any = {
    email: '',
    password: '',
    password2: '',
  };
  preloader = false;
  builder: any;
  error: any;
  constructor(private cnt: ContainerService, private router: Router) { }
  ngOnDestroy() {
    this.form = null;
    this.builder = null;
    this.error = null;
  }

  ngOnInit(): void {

    this.builder = this.cnt.get('form.builder').createForm(this.form);
    const TYPE = this.builder.TYPE;
    this.builder
      .action((_this: any) => {
        _this.button('submit', {
          label: 'Зарегистрироваться',
          disabled: () => {
            return this.preloader;
          }
        })
      })
      .add('email', TYPE.TextType, {
        label: 'E-Mail',
        hine: 'Адрес электронной почты',
        required: true,
        disabled: () => {
          return this.preloader;
        }
      })
      .add('password', TYPE.PasswordType, {
        label: 'Пароль',
        required: true,
        disabled: () => {
          return this.preloader;
        }
      })
      .add('password2', TYPE.PasswordType, {
        label: 'Пароль еще раз',
        required: true,
        disabled: () => {
          return this.preloader;
        }
      });
    this.updateMeta();


  }

  onClickSend() {

    if (this.form.password !== this.form.password2) {
      return alert('Пароли не совпадают');
    }
    this.preloader = true;
    this.error = null;
    this.cnt.api.send('security/register', { email: this.form.email, password: this.form.password }).subscribe((result: { error?: any; token: string; user: any }) => {
      this.preloader = false;
      // localStorage.setItem('_o___ll_user', result.token);
      this.cnt.user.logon(result.user.rules, result.user);
      this.router.navigate(['office']);
    }, (error: any) => {
      this.preloader = false;
      this.error = error;
    })
  }

  updateMeta() {
    this.cnt.setTitle('Регистрация в личном кабинете');
    this.cnt.setDescription('Регистрация');
    this.cnt.setKeyWords('');
  }
}

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerService } from '@container';


// declare var container: any;
declare var moment: any;

@Component({
  selector: 'app-record-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class RecordTicketComponent implements OnInit, OnDestroy {


  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() hospital: any;
  @Input() record: any;

  hide_empty = false;

  error = null;


  dates = [];
  preloader = false;

  constructor(private router: Router, private cnt: ContainerService) { }
  ngOnDestroy() {
    this.hospital = null;
    this.record = null;
    this.error = null;
    this.dates = null;
  }

  ngOnInit(): void {
    this.preloader = true;
    this.cnt.api.send('record/getTickets', { hospital_id: this.hospital.id, person: this.record.person }).subscribe((tickets: any) => {
      this.error = null;
      this.preloader = false;
      var m = moment(new Date()).subtract(1, 'days');
      this.dates = Array(this.hospital.days||30).fill(0).map(() => {
        m.add(1, 'days');
        const date = m.format('YYYY-MM-DD');
        const ticket = tickets.find((ticket) => {
          return ticket.date === date;
        });
        const r = {
          date: date,
          date_title: m.format('dd').toUpperCase() + ', ' + m.format(' DD MMMM YYYY'),
        }
        if (ticket) {

          r['denyCause'] = ticket.denyCause;
          r['begTime'] = ticket.begTime;
          r['endTime'] = ticket.endTime;
          r['record_available'] = ticket.record_available;
          r['tickets'] = ticket.tickets;
        } else {
          r['error'] = false;
        }
        return r;
      })
    }, (error: any) => {
      this.preloader = false;
      this.error = error.message;

    });
  }

  onSelectTicket(ticket: any) {
    this.onSelect.next(ticket);

  }

}

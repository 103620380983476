import {animate, style, transition, trigger} from '@angular/animations';
/*
export function isAnimateActive(): boolean {
    con_sole.log('isAnimateActive = ', (window as any).ANIMATE_ACTIVE);
    return (window as any).ANIMATE_ACTIVE;
}

*/

export const routeAnimation =
    trigger('routeAnimation', [
        transition('* => *', [
            style({opacity: 0}),
            animate('0.30s', style({opacity: 1}))
        ]),

    ]);

export const showAnimation =
    trigger('showAnimation', [
        transition('void => *', [
            style({height: 0, overflow: 'hidden'}),
            animate('0.15s ease-in-out', style({height: '*'})),

        ]),
        transition('* => void', [
            style({overflow: 'hidden'}),
            animate('0.15s ease-in-out', style({height: 0})),
        ])


    ]);


export const opacityAnimation =
    trigger('opacityAnimation', [
        transition('void => *', [
            style({opacity: 0}),
            animate('0.25s ease-in-out', style({opacity: 1})),

        ]),
        transition('* => void', [
            style({opacity: 1}),
            animate('0.25s ease-in-out', style({opacity: 0})),
        ])


    ]);

export const notificationShowAnimation =
    trigger('notificationShowAnimation', [
        transition('void => *', [
            style({right: '-300px'}),
            animate('0.15s ease-in-out', style({right: '0'})),

        ]),
        transition('* => void', [
            style({}),
            animate('0.15s ease-in-out', style({right: '-300px'})),
        ])


    ]);




export const scaleAnimation =
    trigger('scaleAnimation', [
        transition('void => *', [
            style({ transform: 'scale(0)' }),
            animate('0.45s ease-in-out', style({ transform: 'scale(1)' })),

        ]),
        transition('* => void', [
            style({ transform: 'scale(1)' }),
            animate('0.45s ease-in-out', style({ transform: 'scale(0)' })),
        ])


    ]);

/*
export const fullAnimation =
    trigger('fullAnimation', [
        transition('* => *', [
            animate('2.15s ease-in-out'),

        ]),
        transition('* => void', [
            animate('2.15s ease-in-out'),
        ])


    ]);

*/

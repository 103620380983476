export var BASE_MENU = [
    {
        label: 'Главная',
        name: 'homepage',
        routerLink: [''],
    },
    {
        label: 'Пациентам',
        name: 'homepage',
        routerLink: [''],
        child: [
            // {
            //     label: 'Запись на прием',
            //     name: 'homepage',
            //     routerLink: ['record'],
            //     child:
            //     [
                    {
                        label: 'Запись на прием к врачу',
                        name: 'homepage',
                        routerLink: ['record'],
                    },
                    // {
                    //     label: 'Запись к врачу по направлению',
                    //     name: 'homepage',
                    //     routerLink: ['cznpv'],
                    // },
                    // {
                    //     type: 'separator',
                    // },
                    // {
                    //     label: 'Запись на платный приём',
                    //     name: 'homepage',
                    //     routerLink: ['commercial'],
                    // },
                    // {
                    //     label: 'Запись на диагностику',
                    //     name: 'homepage',
                    //     routerLink: ['diagnostic'],
                    // }

                // ]
            // },
            // {
            //     type: 'separator',
            // },
            {
                label: 'Получить результаты анализов',
                name: 'homepage',
                routerLink: ['analyzes']
            },
            // {
            //     type: 'separator',
            // },
            {
                label: 'Проверить/снять запись в медучреждении',
                name: 'homepage',
                routerLink: ['tc']
            },
            // {
            //     label: 'Снять запись',
            //     name: 'homepage',
            //     routerLink: ['cancel']
            // },
            // {
            //     type: 'separator',
            // },
            // {
            //     label: 'Аннотации лекарств',
            //     name: 'homepage',
            //     routerLink: ['drugs'],
            // }

        ]


    },
    {
        label: 'Медучреждения',
        name: 'homepage',
        routerLink: ['hospitals']
    },
    {
        label: 'Вопросы/Ответы',
        name: 'aq',
        routerLink: ['aq']
    },
    // {
    //   label: 'Рейтинг',
    //   name: 'homepage',
    //   routerLink: ['']
    // },


];

export var AUTH_MENU = [
    {
        label: 'Вход',
        name: 'homepage',
        routerLink: ['logon'],
    },
    {
        label: 'Регистрация',
        name: 'homepage',
        routerLink: ['register'],
    }
];

import { Component, OnInit, OnDestroy } from '@angular/core';

import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerService } from '@container';

@Component({
  selector: 'app-page-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class PageErrorComponent implements OnInit, OnDestroy {
  private alive = true;
  code: string;
  constructor(private activatedRoute: ActivatedRoute, private cnt: ContainerService, private router: Router) { }

  ngOnDestroy(): void {
    this.alive = false;
  }
  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params) => {
        this.code = params.code;
      });
  }

}

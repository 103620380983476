import { Component, OnInit, Input, ChangeDetectorRef, AfterViewInit, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

export interface MENU_ITEM {
  label?: string;
  name?: string;
  type?: 'separator';
  icon?: string;
  role?:string[]|string;
  routerLink?: string[];
  key?: string;
  child?: MENU_ITEM[];
  click?: EventEmitter<MENU_ITEM>;
}

@Component({
  selector: 'elm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElmMenuComponent implements OnInit, AfterViewInit {

  @Input() menu: MENU_ITEM[] = [];
  @Input() icons = true;
  @Input() over_child_show = true;

@Input() align='left';

  _show: string;
  get show() {
    return this._show;
  }
  set show(show: string) {
    this._show = show;
    this.cd.detectChanges();
  }

  constructor(private cd: ChangeDetectorRef) { }



  ngOnInit(): void {
    this.cd.detach();
  }
  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }


  onClick(item: MENU_ITEM) {
    if (this.over_child_show) {
      return ;
    }
    if (item.child) {
      this.show = this.show ? null : item.name;
    } else {
      this.show = null;
    }
  }
  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilderComponent } from './form-builder.component';
import { MdElementsModule } from '../md-elements/md-elements.module';
import { FormBuilderRouterComponent } from './form-router/form-router.component';
import { FormsModule } from '@angular/forms';




@NgModule({
  declarations: [FormBuilderComponent, FormBuilderRouterComponent],
  imports: [
    CommonModule,
    FormsModule,
    MdElementsModule,
  ],
  exports:[
    // FormsModule,
    FormBuilderComponent,
    FormBuilderRouterComponent
  ]
})
export class FormBuilderModule { }

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContainerService } from '@container';
import Hospital from '@model/Hospital';
import { Person } from '@model/Person';
import { rbSpeciality } from '@model/rbSpeciality';
import { showAnimation } from '@helper/animations';




@Component({
  selector: 'app-record-specAndPerson',
  templateUrl: './spec-and-person.component.html',
  styleUrls: ['./spec-and-person.component.scss', './../record.component.scss'],
  animations: [
    showAnimation,

  ]
})
export class RecordSpecAndPersonComponent implements OnInit, OnDestroy {
  @Input() hospital: Hospital;
  @Input() record: any;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  persons2spec: any = {};
  specialities: rbSpeciality[] = [];
  open_all = false;
  error: null;
  open_speciality: string;
  preloader = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private cnt: ContainerService, @Inject(PLATFORM_ID) private platformId) { }
  ngOnDestroy() {
    this.hospital = null;
    this.record = null;
    this.persons2spec = null;
    this.specialities = [];
    this.error = null;
  }

  ngOnInit(): void {
    if (this.platformId!=='browser') {

      return ;
    }
    this.open_all = this.platformId === 'browser' ? false : true;
    this.preloader = true;
    // , {/* transferState: 'record/getPersons:' + this.hospital.id*/ }
    this.cnt.api.send('record/getPersons', { hospital_id: this.hospital.id }).subscribe((persons: any) => {
      this.preloader = false;

      let test = {};
      persons.sort((a: any, b: any) => {
        return a.fullName > b.fullName ? 1 : -1;
      });


      const person_external_id = this.activatedRoute.snapshot.params['person_external_id'];


      persons.forEach((person: Person) => {
        if (person_external_id && person.external_id == person_external_id) {

          this.record.person = person;
        }
        person.speciality.forEach((speciality: rbSpeciality) => {
          if (!this.persons2spec[speciality.external_id]) {
            this.persons2spec[speciality.external_id] = [];
          }
          this.persons2spec[speciality.external_id].push(person);
          if (!test[speciality.external_id]) {
            test[speciality.external_id] = true;
            this.specialities.push(speciality);
          }
        });




      });
      // let specialities = [];

      // this.specialities.forEach((spec:any)=>{
      //   specialities.push(spec.title);
      // })
      // this.cnt.api.send('paid/findBySpeciality', { speciality: specialities}).subscribe((list:any)=>{

      // });

      if (this.record.person) {
        this.onSelectPerson(this.record.person);
      }


      this.specialities.sort((a: rbSpeciality, b: rbSpeciality) => {
        return a.title > b.title ? 1 : -1;
      });
    }, (error: any) => {
      this.preloader = false;
      this.error = error;
    });
  }

  onSelectPerson(person: any) {
    // this.record.person = person;

    this.onSelect.next(person);

  }

}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'n2br'
})
export class n2brPipe implements PipeTransform {
    constructor(/*private _sanitizer: DomSanitizer*/) {
    }

    transform(value: any, _args?: any): any {

        if (!value) {
            return '';
        }
        return value.replace(/\n/g, '<br>');
    }

}
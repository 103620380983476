import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title, TransferState } from '@angular/platform-browser';
import rbRegion from '../models/rbRegion';
import Hospital from '../models/Hospital';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
declare var containerjs: any;

@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  hospitals: Hospital[];
  show_ads_index = 0;


  active_messenger = false;

  forms: any = null;

  metadata: any = {};

  base_rsa_page_number = 0;


  regions: rbRegion[];
  containerjs: any;
  // cache: any = {};
  mobile: boolean;
  window_size: {
    width: number;
    height: number;
  };
  theme: 'default' | '9may' | 'newyear' | '8marta' = 'default';
  toolbar_title = 'toolbar_title';
  iframe = false;
  testAuth = false;
  observable_region = new Subject<rbRegion>();
  _current_region;
  initialize: boolean = true;
  set current_region(value: rbRegion) {

    if (value === this.current_region) {
      return;
    }

    if (this.observable_region) {
      this.observable_region.next(value);
    }
    this._current_region = value;
    if (this.__user.auth) {
      const user = this.__user.user;
      if (!user.config) {
        user.config = [];
      }

      const region_id = value ? value.id || null : null;
      if (user.config.region != region_id) {
        user.config.region = region_id;
        this.api.send('profile/updateConfig', user.config).subscribe();
      }
    }

  }
  get current_region() {
    return this._current_region;
  }
  constructor(private title: Title, private meta: Meta, public __user: UserService, public api: ApiService, private state: TransferState, private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId) {
    // this.initialize = false;
    this.containerjs = containerjs;
    this.containerjs.register('api', this);
  }

  get active() {
    return this.__user.user?.active;
  }

  get user() {
    return this.__user;
  }

  /**
   * Авторизирован ли пользователь
   * @return boolean
   */
  get auth(): boolean {
    return this.__user.auth;
  }

  isGranted(role: string) {
    return this.__user.isGranted(role);
  }

  setTitle(title: string): ContainerService {
    this.metadata.title = title;
    this.title.setTitle(title);
    return this;
  }

  setDescription(description: string): ContainerService {
    this.metadata.description = description;
    this.meta.updateTag({ name: 'description', content: description });
    return this;
  }

  setKeyWords(keywords: string): ContainerService {
    this.metadata.keywords = keywords;
    this.meta.updateTag({ name: 'keywords', content: keywords });
    return this;
  }

  get(name: 'form.builder' | 'form.builder.type'): any {
    return this.containerjs.get(name);

  }

  init(options: any, callback: any) {
    this.containerjs.init(options, callback);
  }

  send(method: string, data: any, options: any, resolve: (result: any) => void) {
    if (method.includes('form.builder')) {
      return new Observable((observable: any) => {
        return this.httpClient.post(environment.url+'/api?method='+method, {method:method,params:data}, options)
          .pipe(map(result => {
            try {
              const d = JSON.parse(result as any);
              return resolve(d.result);
            } catch (e) {

            }
            return resolve(result);
          }))
          .subscribe((result:any) => {
            // console.log('>>>>>>>>>>',result);
            observable.next(result);
            observable.complete();

          })
      });
    }

    return this.api.send(method, data);
  }
  http(url: string, data: any, resolve: (result: any) => void, options: any) {
    if (data?.method?.includes('form.builder')) {
      return new Observable((observable: any) => {
        return this.httpClient.post(url, data, options)
          .pipe(map(result => {
            try {
              const d = JSON.parse(result as any);
              return resolve(d.result);
            } catch (e) {

            }
            return resolve(result);
          })).subscribe(result => {
            observable.next(result);
            observable.complete();

          })
      });
    }

    return new Observable((observable: any) => {
      if (options && options.transferState) {
        const data: any = this.state.get(options.transferState, null as any);
        if (data) {
          observable.next(data);
          observable.complete();
          return;
        }
      }

      if (!options) {
        options = {
          headers: {}
        };
      }
      if (!options.headers) {
        options.headers = {};
      }
      // if (localStorage.getItem('_o___ll_user')) {
      //   options.headers['Authorization'] = localStorage.getItem('_o___ll_user');
      // }


      return this.httpClient.post(url, data, options)
        .pipe(map(result => {
          return resolve(result);
        }))
        .subscribe((result: any) => {
          // setTimeout(() => {

          if (!result || !result.result || result.error) {
            observable.error(result ? result.error : { message: 'unknown error', code: 0 });

          } else {

            observable.next(result.result);
            if (options && options.transferState) {
              this.state.set(options.transferState, result.result as any);
            }
          }
          observable.complete();
          // },(this.platformId !== 'browser' || url.includes('session.js') || url.includes('method=data')) ? 0 : 2500);


        }, (error: any) => {
          console.error('Ошибка запроса к серверу:\n' + error.message);
          observable.error(error);
          observable.complete();
        });
    });
  }



}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isConstructorDeclaration } from 'typescript';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient, private state: TransferState, @Inject(PLATFORM_ID) private platformId) {
  }

  send(method: string, args: any = {}, options: { preloader?: () => void, transferState: any } | null = null): Observable<any> {
    return new Observable((observable: any) => {
      if (options?.transferState) {
        const data: any = this.state.get(options.transferState, null as any);
        if (data) {
          observable.next(data);
          observable.complete();
          return;
        }
      }
      if (options?.preloader) {
        options.preloader();
      }

      const data = {
        method,
        params: args,
      }
      this.httpClient.post(environment.url + '/api?method=' + method, data).subscribe((result: any) => {
        //   console.log('result.result',url,data, result);
        setTimeout(() => {

          if (!result || !result.result || result.error) {
            observable.error(result ? result.error : { message: 'unknown error', code: 0 });

          } else {

            observable.next(result.result);

            if (options?.transferState) {
              this.state.set(options.transferState, result.result as any);
            }
          }
          observable.complete();
        }, (this.platformId !== 'browser' ? 0 : 1000));


      }, (error: any) => {
        console.error('Ошибка запроса к серверу:\n' + error.message);
        observable.error(error);
        observable.complete();
      });
    });
  }

}
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ContainerService } from '@container';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';

export interface iFormBuidlerRouterData {
  cancelURL: string;
  updateURL: string;
  updateMethod: string;
  package_name: string;
  form_name: string;
  source: Function
  preUpdate?: Function;
}

@Component({
  selector: 'app-form-builder-router',
  template: '<app-form-builder [builder]="builder" *ngIf="builder" (onSubmit)="onSubmit()" (onCancel)="onCancel()"></app-form-builder>',
})
export class FormBuilderRouterComponent implements OnInit, OnDestroy {

  private alive = true;
  builder: any;
  data: iFormBuidlerRouterData;
  values = null;
  load = 0;
  total = 0;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private cnt: ContainerService) { }
  ngOnDestroy(): void {
    this.alive = false;
    this.builder.destroy();
    this.builder = null;
    this.data = null;
  }


  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params: iFormBuidlerRouterData) => {
        this.data = this.activatedRoute.snapshot.data.form_builder as iFormBuidlerRouterData;
        this.onLoadForm(this.data.package_name, this.data.form_name);
        const source = this.data.source({
          data: this.data,
          params: params,
        });
        if (!source) {
          this.values = {};
        } else {
          this.total++;
          this.cnt.api.send(source.method, source.args).subscribe((result) => {
            this.values = source.filter ? source.filter(result) : result;
            this.next();

          })
        }
      });
  }

  next() {
    this.load++;
    if (this.load === this.total) {
      this.builder.setValues(this.values);
    }
  }


  onSubmit() {
    const data = this.data.preUpdate ? this.data.preUpdate(this.builder.getValues()) : this.builder.getValues();
    this.cnt.api.send(this.data.updateMethod, data).subscribe((result: any) => {
       this.router.navigateByUrl(this.data.updateURL);
    });

  }

  onCancel() {
    this.router.navigateByUrl(this.data.cancelURL);
  }


  onLoadForm(package_name: string, form_name: string): void {
    this.total++;
    this.cnt.get('form.builder').load(package_name, form_name).subscribe((form: any) => {

      if (form.error) {
        return;
      }

      var builder = this.cnt.get('form.builder').createForm();
      form(builder, this.cnt.get('form.builder.type'));

      this.builder = builder;
      this.next();
    }, (e: any) => {
      console.error('#125.135', e);
    });
  }

}

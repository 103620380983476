import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ucfirst'
})
export class UcfirstPipe implements PipeTransform {
    static ucfirst(str: string): string {
        if (!str) {
            return '';
        }
        const a: string[] = str.split(' ');
        for (let i = 0; i < a.length; i++) {
            a[i] = a[i].substring(0, 1).toUpperCase() + a[i].substring(1).toLowerCase();
        }
        return a.join(' ');
    }

    transform(value: any, _args?: any): any {

        return UcfirstPipe.ucfirst(value);
    }
}

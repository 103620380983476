import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { ContainerService } from '@container';
import Hospital from '@model/Hospital';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { ActivatedRoute, Router } from '@angular/router';
import { Feedback } from '@model/Feedback';
import { ElmInputComponent } from 'app/md-elements/input/input.component';
import { Validator } from 'app/md-elements/type/iValidator';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class HospitalFeedbackComponent implements OnInit, OnDestroy {
  private alive = true;
  hospital: Hospital;
  preloader = false;
  feedbacks: Feedback[];
  error: any;
  type: 'board' | 'feedback';
  add = false;
  name: string;
  body: string;
  success_add = false;
  preloader_add = false;
  @ViewChildren(ElmInputComponent, {}) inputs: QueryList<ElmInputComponent>;

  constructor(private activatedRoute: ActivatedRoute, private cnt: ContainerService, private cd: ChangeDetectorRef,private router:Router) { }

  ngOnDestroy(): void {
    this.alive = false;
    this.hospital = null;
    this.feedbacks = [];
    this.error = null;


  }
  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params: { type: 'feedback' | 'board', name: string }) => {
        this.add = false;
        this.type = params.type;
        this.hospital = this.cnt.hospitals.find((h: Hospital) => {
          return h.name === params.name;
        });
        if (!this.hospital) {
          return this.router.navigate(['error', '404']);

        }

        this.cnt.api.send('hospital/getFeedbacks', { hospital_id: this.hospital.id, type: this.type }, { preloader: () => { this.preloader = true; }, transferState: 'hospital/getFeedback:' + this.hospital.id + '.' + this.type}).subscribe((result: Feedback[]) => {
          this.preloader = false;
          this.feedbacks = result;
          this.cd.detach();
          this.cd.detectChanges();
        }, (error: any) => {
          this.preloader = false;
          this.error = error;
          this.cd.detectChanges();
        });
        this.updateMeta();

      });
  }

  private updateMeta() {
    const description: string[] = [];
    description.push(this.hospital.fullTitle);
    if (this.hospital.parent) {
      description.push(this.hospital.parent.fullTitle);
    }
    description.push(this.hospital.address);
    description.push(' медицинские учреждения ' + (this.hospital.region ? this.hospital.region.title : ''));


    this.cnt.setDescription(description.join(' '));
    if (this.type==='board') {
      this.cnt.setTitle('Объявления ' + this.hospital.fullTitle);
      this.cnt.setKeyWords('объявления, время работы, специалисты');
    } else {
    this.cnt.setTitle('Отзывы ' + this.hospital.fullTitle);
    this.cnt.setKeyWords('Отзывы, врачи, специалистам');
    }
  }

  onClickAdd() {
    this.add = true;
    this.cd.detectChanges();
  }

  onSend() {
    let valid = true;
    this.inputs.forEach((input: any) => {//Validator
      if (input.validator) {
        if (!input.getValue() || !input.getValue().trim()) {
          input.valid = false;
          valid = false;
        } else {
          input.valid = true;

        }
      }
    });
    if (!valid) {
      this.cd.detectChanges();
      return;
    }
    this.preloader_add = true;
    this.cd.detectChanges();
    this.cnt.api.send('hospital/addFeedback', { hospital_id: this.hospital.id, name: this.name, body: this.body, type: this.type }).subscribe((result: any) => {
      this.preloader_add = false;
      this.success_add = true;
      this.cd.detectChanges();
    }, (error: any) => {
      this.preloader_add = false;
      this.success_add = true;
      this.cd.detectChanges();
    });
  }

}

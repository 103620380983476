import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerService } from '@container';
import Hospital from '@model/Hospital';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';

@Component({
    selector: 'app-hospital-menu',
    templateUrl: './hospital.menu.component.html',
    styleUrls: ['./hospital.menu.component.scss']
})
export class HospitalMenuComponent implements OnInit, OnDestroy {

    private alive = true;
    hospital: Hospital;
    @Input() sheet: 'schedule' | 'feedback' | 'record' | 'board' | 'persons';
    constructor(private activatedRoute: ActivatedRoute, public cnt: ContainerService,private router:Router) { }

    ngOnDestroy(): void {
        this.alive = false;
        this.hospital = null;
    }

    ngOnInit(): void {

        this.activatedRoute.params
            .pipe(takeWhile(() => this.alive))
            .subscribe((params) => {
                this.hospital = this.cnt.hospitals.find((h: Hospital) => {
                    return h.name === params.name;
                });
                if (!this.hospital) {
                    console.error('#Error app-hospital-menu',params,this.hospital);
                    if (!this.hospital) {
                        return this.router.navigate(['error', '404']);

                    }
                }
            });
    }

}

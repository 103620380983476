import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, HostListener, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { Observable } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { MENU_ITEM } from './md-elements/menu/menu.component';
import { Router, NavigationEnd } from '@angular/router';
import { ContainerService } from '@container';
import rbRegion from './models/rbRegion';
import { notificationShowAnimation, routeAnimation, scaleAnimation, showAnimation } from './helper/animations';
import { environment } from './../environments/environment';
import { TransferState } from '@angular/platform-browser';
import { BASE_MENU, AUTH_MENU } from './helper/base_menu';
import Hospital from '@model/Hospital';
import { ApiService } from './service/api.service';
import { UserService } from './service/user.service';
// declare var container: any;
declare var Ya: any;

declare var ga: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    showAnimation,
  ]
})
export class AppComponent implements OnInit, AfterViewInit {

  //pageNumber = 1;

  base_menu: MENU_ITEM[] = BASE_MENU as any;
  auth_menu: MENU_ITEM[] = AUTH_MENU as any;


  @ViewChild('content_wrap', { static: false }) content_wrap: ElementRef;

  rsa_ts = 0;
  confirm_code: string = '';
  confirm_resend = false;
  confirm_preloader = false;
  confirm_error = null;
  full_url: string;

  liveInternet_url = '';;

  // @ViewChild('yashare') ya_share: ElementRef;

  regions: rbRegion[];
  show_mobile_menu = false;
  current_year = (new Date()).getFullYear();

  // granted: any;
  // initialize = false;
  get_data = false;

  constructor(private state: TransferState, private httpClient: HttpClient, public user: UserService, private api: ApiService, public router: Router, public cnt: ContainerService, @Inject(PLATFORM_ID) private platformId, private cd: ChangeDetectorRef) {

    // if (this.platformId === 'browser') {
    //   alert('13232');
    //   localStorage.setItem('_oll_user', '15341fa67daf9ab4805e08d743cefd78');

    // }
  }
  ngAfterViewInit(): void {
    /*if ((window as any).Ya && this.ya_share) {
      Ya.share2(this.ya_share.nativeElement, {
        content: {
          url: 'https://onlinelpu.ru',
          description: "OnLineLPU Запись на прием к врачу",
          title: "OnLineLPU",
          image: "https://onlinelpu.ru/assets/images/favicon-96.png"
        },
        theme: {
          services: "vkontakte,facebook,odnoklassniki,moimir,gplus,twitter,lj,viber,whatsapp",
          lang: 'ru',
          // limit: 3,
          size: 'm',
          bare: false
        }
        // здесь вы можете указать и другие параметры
      });
    }*/

  }




  // get user() {
  //   return this.cnt.get('security').getUser();
  // }

  // get active() {
  //   if (this.auth) {
  //     return this.user.active;
  //   }
  //   return true;
  // }
  // get auth() {
  //   return this.cnt.get('security').isAuth();
  // }

  // isGranted(role: string) {
  //   return this.cnt.get('security').isGranted(role);
  // }

  ngOnInit() {
    // if (this.platformId==='browser') {
    //   this.loadGA();
    // }
    this.initEventRouter();
    this.onResize();
    let args: any = {
      url: environment.url,//http://onlinelpu4.dev.astio.org/',
      http: (url: string, data: any, resolve: (result: any) => {}, options: any) => {
        return this.cnt.http(url, data, resolve, options);
      },
      httpCache: (data: any) => {
        return new Observable((observable: any) => {
          observable.next(data);
          observable.complete();
        });
      },
      security: {
        firewall: 'onlinelpu'
      },
      /*crypto: {
        algorithm: 'AES-CBC',
        bytes: 512,
      },*/
      auth: {
        client: 'onlinelpu.ru',
        version: environment.version
      }
    };

    if (this.platformId === 'browser') {
      const user = localStorage.getItem('_oll_user');
      // this.api.send('data').subscribe(result => {
      this.init((window as any).DATA);
      if (user && !this.cnt.user.user) {
        this.api.send('security/oldinit', { user }).subscribe(result => {
          if (result.user) {
            localStorage.removeItem('_oll_user');
            document.location.reload()
            this.cnt.user.logon(result.user.rules, result.user);
          }
        });
      }
    } else {
      this.init((window as any).DATA);
    }
    // })
    // this.cnt.init(args, () => {
    //   this.cnt.api.setMethodPrefix('');
    //   if ((window as any).DATA) {
    //     this.init((window as any).DATA);
    //   } else {
    //     this.cnt.api.send('data', {}, { tr__ansferState: 'data' }).subscribe((result: any) => {
    //       this.init(result);
    //     });
    //   }



    // });






  }

  init(result: { hospitals: Hospital[], regions: rbRegion[] }) {
    result.regions.forEach((region) => {
      if (region.parent) {
        const parent = result.regions.find((r: rbRegion) => {
          return r.id === region.parent.id;
        });
        if (parent) {
          region.parent = JSON.parse(JSON.stringify(parent));
        }
      }
    });



    result.hospitals.forEach((hospital) => {
      hospital.region = result.regions.find((r: rbRegion) => {
        return hospital.region && hospital.region.id === r.id;
      });
    });
    result.hospitals.forEach((hospital) => {
      if (hospital.parent) {
        hospital.parent = result.hospitals.find((h: Hospital) => {
          return h.id === hospital.parent.id;
        });
        if (hospital.parent) {
          hospital.parent = JSON.parse(JSON.stringify(hospital.parent));
          delete hospital.parent.child;
        }
      }

      if (hospital.child && hospital.child.length > 0) {
        hospital.child = result.hospitals.filter((parent: Hospital) => {
          if (!parent.parent) {
            return false;
          }
          return parent.parent.id === hospital.id;
        });
        hospital.child = JSON.parse(JSON.stringify(hospital.child));
        hospital.child.forEach((h: Hospital) => {
          delete h.parent;
        });
      }

    });


    this.cnt.hospitals = result.hospitals;

    this.cnt.regions = result.regions;
    this.regions = result.regions.filter((region: rbRegion) => {
      return !region.parent ? true : false;
    })

    const user = this.cnt.user.user;



    // if (user) {
    //   // this.cnt.api.send('profile/formFindAll').subscribe((result: any) => {
    //   //   if (result.length > 0) {
    //   //     this.cnt.forms = result;
    //   //   }
    //   // });


    //   // this.cnt.api.send('sqa/profile.findAll').subscribe((result: any) => {
    //   //   this.cnt.forms = result;
    //   // });



    // }
    if (user && user.config && user.config.region) {
      const find_region: number = user.config.region;
      const region = this.cnt.regions.find((region: rbRegion) => {
        return region.id === find_region;
      });
      if (region) {

        this.cnt.current_region = region.parent ? region.parent : region;
      }
    }

    // this.cnt.initialize = true;
    // this.initialize = true;
    // this.cd.reattach();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const width = window.innerWidth;
    this.cnt.window_size = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    if (width <= 798) {
      if (this.cnt.mobile !== true) {
        this.cnt.mobile = true;
      }
    } else {
      if (this.cnt.mobile !== false) {
        this.cnt.mobile = false;
      }
    }
  }



  onExit($event: Event) {
    $event.stopPropagation();

    this.cnt.api.send('security/logout').subscribe((r: any) => {
      this.cnt.user.logout();
      this.router.navigate(['']);
      // localStorage.removeItem('_o___ll_user');
    }, (e: any) => {
      alert('Ошибка. Не удалось выйти из учётной записи.')
    });

  }


  initEventRouter() {
    this.router.events
      .subscribe(
        (navEnd: NavigationEnd) => {
          if (navEnd instanceof NavigationEnd) {
            this.show_mobile_menu = false;


            try {
              if (window.scrollTo) {
                window.scrollTo(0, 0);
                if (this.content_wrap) {
                  const body = document.getElementsByTagName('body')[0];
                  body.scrollTo(0, 0);
                  // this.content_wrap.nativeElement.scrollTo(0, 0);
                }
              }
            } catch (e) {
              console.error('e', e);
            }
            this.cnt.show_ads_index++;
            // const body = document.body;
            // if (this.cnt.mobile && body) {
            //   this.get_data = true;
            //
            //   body.style.overflow = 'hidden';
            //   setTimeout(() => {
            //     body.style.overflow = 'auto';
            //     this.get_data = false;
            //   }, 3200);
            // } else {
            //   this.get_data = false;
            // }
            this.get_data = false;

            if (navEnd.url.indexOf('?auth') > 0) {
              this.cnt.testAuth = true;
            }

            if (navEnd.url.indexOf('?iframe') > 0) {
              this.cnt.iframe = true;
            }
            try {
              this.full_url = document.location.href.replace(/(.*)\?.*/, '$1')
            } catch (e) {

            }




            try {
              this.liveInternet_url = 'https://counter.yadro.ru/hit?t26.12;r' +
                escape(document.referrer) + ((typeof (screen) == "undefined") ? "" :
                  ";s" + screen.width + "*" + screen.height + "*" + (screen.colorDepth ?
                    screen.colorDepth : screen.pixelDepth)) + ";u" + escape(document.URL) +
                ";" + Math.random();
            } catch (e) {

            }


            try {

              if (ga) {
                const url = (environment.url + navEnd.urlAfterRedirects).replace('//', '/');
                ga('set', 'page', url);
                ga('send', 'pageview');
              }
            } catch (e) {

            }


          }
        });
  }



  onClickConfirm() {
    this.confirm_preloader = true;
    this.cnt.api.send('security/active', { code: this.confirm_code }).subscribe((r: any) => {
      this.confirm_preloader = false;
      this.cnt.user.user.active = true;
      document.location.reload();
    }, (error: any) => {
      this.confirm_preloader = false;
      this.confirm_error = error;
    });
  }

  onClickResendCode() {
    this.confirm_resend = true;
    this.cnt.api.send('security/resendCode').subscribe();
  }

  get is_visible_notice(): boolean {
    if (document.location.pathname === '/office/notice' || document.location.pathname.indexOf('/form/') === 0) {
      return false;
    }

    return true;

  }

}

import { Component, OnInit, ViewEncapsulation, Input, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, ViewChild, ViewChildren, QueryList, ContentChildren, ChangeDetectorRef } from '@angular/core';
import { ElmInputComponent } from '../input/input.component';
import { IValidatorType,Validator } from '../type/iValidator';

@Component({
    selector: 'elm-form',
    template: `<div  style="border:20px solid red;"></div>`,
})
export class ElmFormComponent  {
    constructor(private cd:ChangeDetectorRef) {

    }
    @ViewChildren(ElmInputComponent,{}) inputs: QueryList<ElmInputComponent>;
    @ContentChildren(ElmInputComponent, { descendants: true }) inputs3: QueryList<ElmInputComponent>;


    isValid(): boolean {
        this.cd.detectChanges();
        let elements = [];
        elements = elements.concat(this.inputs.toArray());


        let valid = true;
        
        elements.forEach((input: Validator) => {
            if (input.validator) {
                if (this.__testValid(input.getValue(), input.validator)) {
                    input.valid = true;
                } else {
                    input.valid = false;
                    valid = false;
                }

            }
        });
        return valid;
    }


    private __testValid(value: any, validator: IValidatorType): boolean {
        
        if (!validator) {
            return true;
        }
        if (validator.required) {
            if (!value) {
                return false;
            }
            if (typeof value === 'object') {
                if (Object.keys(value).length === 0) {
                    return false;
                }
            } else if ((!value || value === '' || value === undefined || (Array.isArray(value) && value.length === 0))) {
                return false;
            }
        }
        if (validator.match) {
            const regex = new RegExp(validator.match);
            const test_value = Array.isArray(value) ? value.join('') : value || '';
            
            if (!regex.test(test_value)) {
                return false;
            }
        }
        if (validator.active) {
            if (Array.isArray(value)) {
                for (var i = 0; i < value.length; i++) {
                    if (!value[i].active) {
                        return false;
                    }
                }
            } else {
                if (value && !value.active) {
                    return false;
                }
            }
        }
        return true;
    }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContainerService } from '@container';
import { Router } from '@angular/router';
import rbRegion from '@model/rbRegion';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit,OnDestroy {

  email = '';
  preloader = false;
  error = null;
  success = false;
  password = '';
  code = '';
  password2 = '';
  password_invalid = false;
  constructor(private cnt: ContainerService, private router: Router) { }
  ngOnDestroy() {
    this.error = null;
  }

  ngOnInit(): void {
    if (this.cnt.user.auth) {
      this.router.navigate(['office']);
    }
    this.updateMeta();
  }

  onClickUpdatePassword() {

    if (this.password !== this.password2) {
      this.password_invalid = true;
      return;
    }
    this.password_invalid = false;
    this.preloader = true;
    this.error = null;
    this.cnt.api.send('security/updatePassword', { email: this.email, code: this.code, password: this.password }).subscribe((result: { error?: any; token: string; user: any }) => {
      this.preloader = false;

      if (result.error) {
        this.error = true;
        return;
      }

      // localStorage.setItem('_o___ll_user', result.token);

      this.cnt.user.logon(result.user.rules, result.user);
      const user = this.cnt.user.user;
      if (user.config && user.config.region) {
        const find_region: number = user.config.region;
        const region = this.cnt.regions.find((region: rbRegion) => {
          return region.id = find_region;
        });
        if (region) {
          this.cnt.current_region = region.parent ? region.parent : region;
        }
      }
      this.router.navigate(['office']);


    }, (error: null) => {
      this.preloader = false;
      this.error = error;
    });
  }

  onClickRestore() {
    this.preloader = true;
    this.error = null;
    this.success = false;
    this.cnt.api.send('security/restorePassword', { email: this.email }).subscribe((result: any) => {
      this.preloader = false;
      this.success = true;

    }, (error: null) => {
      this.preloader = false;
      this.error = error;
    });

  }



  updateMeta() {
    this.cnt.setTitle('Восстановление пароля к личному кабинету');
    this.cnt.setDescription('Восстановление пароля');
    this.cnt.setKeyWords('');
  }

}

import { NgModule } from '@angular/core';

import { ElmButtonComponent } from './button/button.component';

import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';

import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ElmInputComponent } from './input/input.component';
import { ElmFormComponent } from './form/form';


import { NgxMaskModule, IConfig } from 'ngx-mask'
import { FormsModule } from '@angular/forms';
import { ElmCalendarComponent } from './calendar/calendar.component';
import { ElmIconComponent } from './icon/icon.component';
import { ElmMenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { ElmSelectComponent } from './select/select.component';
import { CommonModule } from '@angular/common';
import { ElmSpinnerComponent } from './spinner/spinner.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ElmSafeHTMLPipe } from './pipe/safe.html.pipe';


// let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    ElmButtonComponent,
    ElmInputComponent,
    ElmFormComponent,
    ElmCalendarComponent,
    ElmIconComponent,
    ElmMenuComponent,
    ElmSelectComponent,
    ElmSpinnerComponent,
    ElmSafeHTMLPipe,

  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    RouterModule.forChild([]),

    MatRadioModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatProgressBarModule,
    MatIconModule,
    // BrowserAnimationsModule,
    NgxMaskModule.forRoot()//options)
  ],
  exports: [
    // FormsModule,
    ElmButtonComponent,
    ElmInputComponent,
    ElmFormComponent,
    ElmCalendarComponent,
    ElmIconComponent,
    ElmMenuComponent,

    ElmSelectComponent,
    ElmSpinnerComponent,

    MatRippleModule,
    MatInputModule,
    MatTabsModule,
    MatExpansionModule,
    MatMenuModule,
    MatProgressBarModule,
    ElmSafeHTMLPipe,
  ],
  providers: [],
  bootstrap: [],
})
export class MdElementsModule {

}

import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { ContainerService } from '@container';
import { Router, NavigationEnd } from '@angular/router';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { environment } from 'environments/environment';

declare var Ya: any;

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit, OnDestroy {

  private alive = true;
  @Input() type: 'left' | 'base' | 'fullscreen' | 'footer' | 'string' | 'base_mobule';
  //is_home_page = false;

  // base_rsa_page_number = 1;




  list: any = {
    base_desktop: {
      name: 'R-A-123041-9',
      subscribe: true,
    },
    base_desktop2: {
      name: 'R-A-123041-18',
      subscribe: true,
    },
    base_mobile: {
      name: 'R-A-123041-17',
      subscribe: true,
    },
  }
  current: {
    name: string;
    subscribe: boolean;
  };

  rnd = Math.round(Math.random() * 10000000);

  timer_create: any;
  constructor(public cnt: ContainerService, private router: Router, private cd: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId) { }


  ngOnDestroy(): void {
    this.alive = false;
    if (this.timer_create) {
      clearInterval(this.timer_create);
    }
  }

  ngOnInit(): void {
    if (this.platformId !== 'browser') {
      return;
    }
    this.current = this.list[this.type];
    this.cd.detectChanges();


    this.timer_create = setInterval(() => {
      try {
        if (!Ya || !Ya.Context || !Ya.Context.AdvManager) {
          this.cnt.api.send('ads/show', { Error: "not found YA" }).subscribe((result: any) => { });
          return;
        }

        clearInterval(this.timer_create);

        try {

          this.cd.detectChanges();
          if (this.cnt.user.isGranted('ROLE_ADMIN')) {
            // console.info('ADS', this.current.name, this.current);
          }
          this.cnt.base_rsa_page_number++;
          Ya.Context.AdvManager.render({
            blockId: this.current.name,
            renderTo: 'yandex_rtb_' + this.current.name + '-' + this.rnd,
            async: true,
            pageNumber: this.cnt.base_rsa_page_number,
            onRender: (data) => {
              // this.cnt.api.send('ads/show', Object.assign(this.current, { type: this.type, data: data })).subscribe((result: any) => {
              // });
            }
          } as any);




        } catch (e) {

        }



        this.router.events
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (navEnd: NavigationEnd) => {

              if (navEnd instanceof NavigationEnd) {

                try {
                  this.cd.detectChanges();
                  // setTimeout(() => {
                  try {
                    this.cnt.base_rsa_page_number++;
                    Ya.Context.AdvManager.render({
                      blockId: this.current.name,
                      renderTo: 'yandex_rtb_' + this.current.name + '-' + this.rnd,
                      async: true,
                      pageNumber: this.cnt.base_rsa_page_number,
                      onRender: (data) => {
                        // this.cnt.api.send('ads/show', Object.assign(this.current, { type: this.type, data: data })).subscribe((result: any) => {
                        // });
                      }
                    } as any);
                  } catch (e) {

                  }
                  // }, 100);
                } catch (e) {

                }
              }
            });
      } catch (e) {

      }

    }, 250);
  }

  get is_home_page(): boolean {
    return document.location.pathname === '/' ? true : false;
  }
}

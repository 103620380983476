import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { ContainerService } from '@container';
import { ActivatedRoute, Router } from '@angular/router';
import { Person } from '@model/Person';
import Hospital from '@model/Hospital';
import { Schedule } from '@model/Schedule';
import { rbSpeciality } from '@model/rbSpeciality';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';

declare var moment: any;

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class HospitalScheduleComponent implements OnInit, OnDestroy {
  private alive = true;
  dates = [];
  hospital: Hospital;
  schedules: { [person_id: string]: { [date: string]: { begTime: string, endTime: string } } } = {};


  specialities2persons: { title: string, persons: Person[] }[] = [];
  prev_week: string;
  next_week: string;

  preloader = false;


  params:any;
  persons_ids: number[];
  str_dates: string[];

  constructor(public cnt: ContainerService, private activatedRoute: ActivatedRoute, private cd: ChangeDetectorRef,private router:Router) { }
  ngOnDestroy(): void {
    this.alive = false;
    this.dates = [];
    this.hospital = null;
    this.schedules = {};
    this.specialities2persons = [];
    this.persons_ids = [];
    this.str_dates = [];
  }

  ngOnInit(): void {

    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params) => {

        this.params = params;

        this.str_dates = [];
        const current_date = moment(new Date());
        const min_date = current_date.clone().subtract(2, 'week').startOf('week').format('YYYY-MM-DD');
        const max_date = current_date.clone().add(3, 'week').startOf('week').format('YYYY-MM-DD');
        var m = moment(params.date ? new Date(params.date) : new Date()).startOf('week').subtract(1, 'days');
        this.prev_week = m.clone().startOf('week').format('YYYY-MM-DD');
        if (this.prev_week === min_date) {
          this.prev_week = null;
        }
        this.dates = Array(7).fill(0).map(() => {
          m.add(1, 'days');
          this.str_dates.push(m.format('YYYY-MM-DD'));
          return {
            title: this.cnt.mobile ? m.format('dd, DD MMM') : m.format('dd, DD MMMM YYYY'),
            date: m.format('YYYY-MM-DD')
          }
        });
        m.add(1, 'days');
        this.next_week = m.format('YYYY-MM-DD');
        if (this.next_week === max_date) {
          this.next_week = null;
        }



        if (!this.hospital || this.hospital.name !== params.name) {

          this.hospital = this.cnt.hospitals.find((hospital) => {
            return hospital.name === params.name;
          });

          if (!this.hospital) {
            return this.router.navigate(['error', '404']);

          }


          this.updateMeta();


          this.specialities2persons = [];
          this.persons_ids = [];

          this.preloader = true;
          this.cnt.api.send('hospital/getPersons', { hospital_id: this.hospital.id }, { transferState: 'hospital/getPersons/' + this.hospital.id }).subscribe((persons: Person[]) => {
            this.preloader = false;
            var spec = {};
            persons.forEach((person: Person) => {
              this.persons_ids.push(person.id);

              person.speciality.forEach((s: rbSpeciality) => {


                if (!spec[s.id]) {
                  spec[s.id] = {
                    title: s.title,
                    persons: [],
                  }
                }
                spec[s.id].persons.push(person);

              });
            });
            for (var id in spec) {
              this.specialities2persons.push(spec[id]);
            }
            this.specialities2persons.sort((a: any, b: any) => {
              if (a.title === b.title) {
                return 0;
              }
              return a.title > b.title ? 1 : -1;
            });


            this.loadSpeciality();

          });
        } else {
          this.loadSpeciality();
        }
      });
  }

  private updateMeta() {
    if (!this.hospital) {
      console.error('#Error app-hospital-schedule.updateMeta', this.params)
      return ;
    }
    const description: string[] = [];
    description.push(this.hospital.fullTitle);
    if (this.hospital.parent) {
      description.push(this.hospital.parent.fullTitle);
    }
    description.push(this.hospital.address);
    description.push(' медицинские учреждения ' + (this.hospital.region ? this.hospital.region.title : ''));


    this.cnt.setDescription(description.join(' '));
    this.cnt.setTitle('Расписание работы ' + this.hospital.fullTitle);
    this.cnt.setKeyWords('Расписание работы, время работы, время работы врачей, время работы специалистов, приема, приём');


  }


  loadSpeciality() {
    // this.preloader = true;
    this.cd.detectChanges();
    this.cnt.api.send('hospital/getSchedule', { hospital_id: this.hospital.id, persons_ids: this.persons_ids, dates: this.str_dates }, {preloader:()=> { this.preloader = true; }, transferState: 'hospita/getSchedule/' + this.hospital.id + '/' + this.str_dates.join('/') }).subscribe((entities: Schedule[]) => {

      entities.forEach((entity: any) => {
        if (!entity.begTime || !entity.endTime) {
          return;
        }
        if (!this.schedules[entity.person_id]) {
          this.schedules[entity.person_id] = {};
        }
        this.schedules[entity.person_id][entity.date] = { begTime: entity.begTime.split(':'), endTime: entity.endTime.split(':') };
      });

      this.cd.detectChanges();
      this.cd.detach();
      setTimeout(() => {
        this.preloader = false;
        this.cd.detectChanges();
      }, 100);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.cd.detectChanges();
  }
}

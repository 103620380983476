import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ContainerService } from '@container';
import { Router, NavigationEnd } from '@angular/router';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';


declare var Ya: any;

@Directive({
  selector: '.pageTitle'
})
export class PageTitleDirective implements OnInit, OnDestroy {

  private div: any;
  private subscription: any;
  private rnd = Date.now();
  constructor(private elm: ElementRef, private cnt: ContainerService, private router: Router) {

  }
  ngOnInit() {
    // return ;
    if (!this.cnt.mobile) {
      return;
    }
    if (!this.elm) {
      return;
    }




    // if (!Ya || !Ya.Context || !Ya.Context.AdvManager) {
    //   this.cnt.api.send('ads/show', { Error: "not found YA" }).subscribe((result: any) => { });
    //   return;
    // }
    if (this.is_home_page) {
      return;
    }

    try {

      this.div = document.createElement('div');

      this.div.className = "ads-layout";
      // this.div.id = ``;
      this.div.innerHTML = `<div id="yandex_rtb_${this.rnd}"></div>`;
      this.elm.nativeElement.after(this.div)


      this.cnt.base_rsa_page_number++;
      const timer_create = setInterval(() => {

        try {
          Ya.Context.AdvManager.render({
            blockId: 'R-A-123041-18',
            renderTo: 'yandex_rtb_' + this.rnd,
            async: false,
            pageNumber: this.cnt.base_rsa_page_number,
            // onRender: (data) => {
            //   this.cnt.api.send('ads/show', { mobile: true }).subscribe((result: any) => { });
            // }
          });
          clearInterval(timer_create);
        } catch (e) {

        }
      }, 250);




      this.subscription = this.router.events.subscribe((navEnd: NavigationEnd) => {
        if (navEnd instanceof NavigationEnd) {

          this.ngOnDestroy();
          this.ngOnInit();
        }
      }
      )


    } catch (e) {
      console.error(e);
    }
  }
  ngOnDestroy(): void {
    try {
      if (this.div) {
        this.div.remove();
      }
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }
    } catch (e) {
      console.error(e);
    }
  }

  get is_home_page(): boolean {
    return document.location.pathname === '/' ? true : false;
  }

}

import { Component, OnInit, ContentChildren, QueryList, AfterViewInit, ElementRef, ViewContainerRef, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { SheetComponent } from './sheet/sheet.component';
import { ContainerService } from '@container';

@Component({
  selector: 'app-sheets',
  templateUrl: './sheets.component.html',
  styleUrls: ['./sheets.component.scss']
})
export class SheetsComponent implements OnInit,OnDestroy, AfterViewInit {

  @ContentChildren(SheetComponent) sheets: QueryList<any>;

  constructor(public cnt:ContainerService,private cd:ChangeDetectorRef) {
    
   }
   ngOnDestroy() {
     this.sheets = [] as any;
   }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  }

  onClick() {
    this.cd.detectChanges();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerService } from '@container';
import rbRegion from '@model/rbRegion';



@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.scss']
})
export class LogonComponent implements OnInit, OnDestroy {

  datas = {
    vk:
    {
      width: 620,
      height: 370,
      url: `https://oauth.vk.com/authorize?&display=popup&client_id=4148925&scope=video&redirect_uri=${encodeURI('https://onlinelpu.ru/auth/vk')}&response_type=code`
      // url: `https://oauth.vk.com/authorize?&display=popup&client_id=4148925&scope=video&redirect_uri=${encodeURI('http://onlinelpu4.dev.astio.org/auth/vk')}&response_type=code`

    },
    mail:
    {
      width: 620,
      height: 370,
      url: `https://connect.mail.ru/oauth/authorize?client_id=717673&redirect_uri=${encodeURI('https://onlinelpu.ru/auth/mail')}&response_type=code`
    },
    odn:
    {
      width: 620,
      height: 370,
      url: `http://www.odnoklassniki.ru/oauth/authorize?client_id=316749312&redirect_uri=${encodeURI('https://onlinelpu.ru/auth/odn')}&response_type=code`
    },
    google:
    {
      width: 620,
      height: 550,
      url: `https://accounts.google.com/o/oauth2/auth?client_id=302575912696-n2h6u9n3qkjinv4eklb7ge1tauf12bf3.apps.googleusercontent.com&redirect_uri=${encodeURI('https://onlinelpu.ru/auth/google')}&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile`
    },
    yandex:
    {
      width: 620,
      height: 620,
      url: `https://oauth.yandex.ru/authorize?response_type=code&client_id=c3663701ee564157a42fb7b0bfed610b`
    },
  };

  popupOpened: boolean;
  popup: any;
  email: string = '';
  password: string = '';
  error = null;
  preloader = false;
  constructor(private router: Router, public cnt: ContainerService) { }
  ngOnDestroy() {
    this.error = null;
  }

  ngOnInit(): void {
    if (this.cnt.user.auth) {
      this.router.navigate(['office']);
    }
    this.updateMeta();
  }


  onLogon() {
    this.error = null;
    this.preloader = true;
    this.cnt.api.send('security/logon', { email: this.email, password: this.password }).subscribe((result: { error?: any; token: string; user: any }) => {


      if (result.error) {
        this.preloader = false;
        this.error = true;
        return;
      }


      // localStorage.setItem('_o___ll_user', result.token);


      this.cnt.user.logon(result.user.rules, result.user);

      const user = this.cnt.user.user;
      if (user.config && user.config.region) {
        const find_region: number = user.config.region;
        const region = this.cnt.regions.find((region: rbRegion) => {
          return region.id = find_region;
        });
        if (region) {
          this.cnt.current_region = region.parent ? region.parent : region;
        }
      }

      this.router.navigate(['office']);
    }, (error: any) => {
      this.preloader = false;
      this.error = true;
    });

  }

  onClickOAuth(type: string) {
    var data = this.datas[type];
    //???that.url_redirect = "https://onlinelpu.ru/office/profile";
    this.WindowOpen(data.width, data.height, data.url);
  }

  WindowOpen(width: number, height: number, url: string) {
    let screenX: number = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft;
    let screenY: number = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop;
    let outerWidth: number = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.body.clientWidth;
    let outerHeight: number = typeof window.outerHeight != 'undefined' ? window.outerHeight : (document.body.clientHeight - 22);

    let left = screenX + ((outerWidth - width) / 2);
    let top = screenY + ((outerHeight - height) / 2.5);
    const features = (
      'width=' + width +
      ',height=' + height +
      ',left=' + left +
      ',top=' + top
    );
    this.popupOpened = true;
    this.popup = window.open(url, "popup_oauth", features);
    setTimeout(() => {
      this.popupCheck();
    }, 100);
  }

  popupCheck() {
    if (!this.popupOpened) return false;
    var div = null;
    try {
      div = this.popup.document.getElementById("oauth_key");
    } catch (e) {
      setTimeout(() => {
        this.popupCheck();
      }, 100);
      return;
    }
    // var oauth_key = div.innerHTML;
    if (div) {//}!=null && oauth_key!="") {
      // localStorage.setItem('_o___ll_user', div.innerHTML);
      this.popup.close();
      this.popupOpened = false;
      document.location.href = '/office';
      //app.redirect(_FILE.base+"logon.php/key/"+oauth_key);
      return true;
    }
    if (this.popup.closed) {
      this.popupOpened = false;
      return true;
    }
    setTimeout(() => {
      this.popupCheck();
    }, 100);
  }

  updateMeta() {
    this.cnt.setTitle('Вход в личный кабинет');
    this.cnt.setDescription('Вход');
    this.cnt.setKeyWords('');
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleDirective } from './page-title.directive';




@NgModule({
  declarations: [
    PageTitleDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PageTitleDirective
    
  ]
})
export class PageTitleModule { }

import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ElmInputComponent } from 'app/md-elements/input/input.component';
import { ContainerService } from '@container';
import { ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { rbSpeciality } from '@model/rbSpeciality';

@Component({
  selector: 'app-test-cancel',
  templateUrl: './test-cancel.component.html',
  styleUrls: ['./test-cancel.component.scss','./../../office/history/history.component.scss']
})
export class TestCancelComponent implements OnInit, OnDestroy {

  private alive = true;
  form: {
    lastName: string;
    code: string;
  }
  builder: any;
  preloader = false;
  hospital:any;
  success: boolean;
  error: any;
  profile:any;
  tickets:any;
  record:any;
  @ViewChildren(ElmInputComponent, {}) inputs: QueryList<ElmInputComponent>;
  constructor(private cnt: ContainerService, private activatedRoute: ActivatedRoute) {
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.builder = null;
    this.form = null;
    this.error = null;
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(takeWhile(() => this.alive)).subscribe((params: any) => {
      this.updateMeta();
      this.form = {
        code: params.code || '',
        lastName: params.lastName || ''
      }
      this.builder = this.cnt.get('form.builder').createForm(this.form);
      const TYPE = this.builder.TYPE;
      this.builder
        .action((_this: any) => {
          _this.button('submit', { label: 'Отправить запрос', })
        })
        .add('code', TYPE.TextType, {
          label: 'Номер записи',
          required: true,
          hint: 'Номер полученный после записи на приём на портале OnLineLPU',
        })
        .add('lastName', TYPE.TextType, { label: 'Фамилия', hint: 'Только Фамилия пациента', required: true, })
    });
  }

  onSubmit() {
    this.preloader = true;
    this.success = null;
    this.error = null;
    this.cnt.api.send('tc/getHistory',this.form).subscribe((result: any) => {
      this.success = true;
      this.preloader = false;
      this.tickets = result.tickets;
      this.hospital = result.hospital;
      this.record  = result.record;
      this.profile = result.profile;
    }, (error: any) => {
      this.error = error;
      this.preloader = false;
    });
  }


  onClickCancel(ticket) {
        ticket.cancel_preloader = true;
        this.cnt.api.send('tc/cancel',{ticket_id:ticket.external_id,record:this.record}).subscribe((result: any) => {
      ticket.cancel_preloader = false;
      ticket.cancel = true;

      this.success = result.success;
    }, (error: any) => {
      ticket.cancel_preloader = false;
      ticket.error = error;

    });
  }

  updateMeta() {
    this.cnt.setTitle('Проверка/Отмена записи к врачу').setDescription('Проверить или снять запись к врачу в медицинском учреждении').setKeyWords('отмена записи к врачу, снять, отменить, проверить запись к врачу, история записи к врачу');
  }

  getSpeciality(speciality: rbSpeciality[]) {
    return speciality.map(s => s.title).join(', ');
  }

}

import { Pipe, PipeTransform } from '@angular/core';


declare var moment: any;

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: string | Date, args: any={format:'DD.MM.YYYY'}): unknown {
    if (!value) {
      return '';
    }
    // if (!value) {
    //   return '';
    // }
    const m = typeof value==='string' ? moment(new Date(value)) : moment(value);
    // const m = moment(value);
    // if (args.utcOffset) {
    // m.utcOffset(180);
    // }
    return m.format(args.format);
  }

}
 
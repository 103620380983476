import { UrlSegment } from '@angular/router';


export function finalMatcher(url: UrlSegment[]) {
  return ({ consumed: url });

}


export function testHospital(url: UrlSegment[]) {
  if (url.length === 1) {
    const name: string = url[0].path.substring(0, 1);
    if (name === 'g' || name === 'p') {
      return ({ consumed: url, posParams: { name: url[0] } });
    }
  }
  return null;
}

export function testCommercial(url: UrlSegment[]) {
  if (url.length >= 1) {
    if (url[0].path === 'commercial') {
      const posParams: any = { name: url[0] };
      if (url.length === 3) {
        if (url[1].path === 'record') {
          posParams['person_docdoc_id'] = url[2];
          posParams['record'] = url[1];
        }
        if (url[1].path === 'limit') {
          posParams['limit'] = url[2];
        }
      }
      return ({ consumed: url, posParams: posParams });
    }
  }
  return null;
}


export function testRecordHospital(url: UrlSegment[]) {
  if (url.length === 1) {
    if (url[0].path === 'reserv') {
      return ({ consumed: url, posParams: { reserv: url[0] } });
    }
  }

  if (url.length >= 2) {
    const name: string = url[0].path.substring(0, 1);
    if (url[1].path === 'record' && (name === 'g' || name === 'p')) {
      let posParams = { name: url[0] };
      if (url[2]) {
        posParams['person_external_id'] = url[2];
      }
      if (url[3]) {
        posParams['profile'] = url[3];
      }
      return ({ consumed: url, posParams: posParams });
    }
  }
  return null;
}

export function testScheduleHospital(url: UrlSegment[]) {
  if (url.length > 1) {
    const name: string = url[0].path.substring(0, 1);
    if (url[1].path === 'schedule' && (name === 'g' || name === 'p')) {
      let posParams = { name: url[0] };
      if (url[2]) {
        posParams['date'] = url[2];
      }
      return ({ consumed: url, posParams: posParams });
    }
  }
  return null;
}


export function testFeedbackHospital(url: UrlSegment[]) {
  if (url.length === 2) {
    const name: string = url[0].path.substring(0, 1);
    if ((url[1].path === 'feedback' || url[1].path === 'board') && (name === 'g' || name === 'p')) {
      return ({ consumed: url, posParams: { name: url[0], type: url[1] } });
    }
  }
  return null;
}


export function testPersonsHospital(url: UrlSegment[]) {
  if (url.length === 2) {
    const name: string = url[0].path.substring(0, 1);
    if (url[1].path === 'persons' && (name === 'g' || name === 'p')) {
      return ({ consumed: url, posParams: { name: url[0] } });
    }
  }
  return null;
}

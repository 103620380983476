import { MomentPipe } from './moment.pipe';
import { UcfirstPipe } from './ucfirst.pipe';
import { SafeHTMLPipe } from './safe.html.pipe';
import { NgModule } from '@angular/core';


@NgModule({
    declarations: [

        UcfirstPipe,
        MomentPipe,
        SafeHTMLPipe


    ],
    imports: [
        
    ],
    exports: [
        UcfirstPipe,
        MomentPipe,
        SafeHTMLPipe
    ]
})
export class PipeModule { }

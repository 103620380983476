import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'elm-icon',
  template: '<mat-icon><ng-content></ng-content></mat-icon>',
})
export class ElmIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, EventEmitter, Output, Input, OnChanges, AfterViewInit, ViewChild, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel } from '@angular/forms';


// function add0(v: number): string {
//   return (v < 10 ? '0' : '') + v;
// }

@Component({
  selector: 'elm-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ElmCalendarComponent, multi: true }]
})
export class ElmCalendarComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {
  ngAfterViewInit(): void {
  }

  // @Output() ngModelChange: EventEmitter<Date> = new EventEmitter();

  // onSelect:EventEmitter<Date> = new     EventEmitter();

  MONTHS = ['янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сен', 'окт', 'ноя', 'дек'];
  NUMBER_YEAR_DISPLAY = 20;


  hours_list = Array(24).fill(0).map((v, i) => {
    return (i < 10 ? '0' : '') + i;
  });

  minutes_list = Array(60).fill(0).map((v, i) => {
    return (i < 10 ? '0' : '') + i;
  });


  view: 'week' | 'month' | 'year' = 'week';

  weeks = [];
  years = [];
  months = [];

  date: Date = new Date();
  current_date = new Date();

  value_hours: string;
  value_minutes: string;
  @Input() type: 'date' | 'datetime' = 'date';



  ngOnChanges(changes: SimpleChanges): void {

  }

  constructor() { }

  ngOnInit(): void {
    this.createWeeks(this.date);

    if (this.type === 'datetime') {

      this.value_hours = (this.date.getHours() < 10 ? '0' : '') + this.date.getHours();
      this.value_minutes = (this.date.getMinutes() < 10 ? '0' : '') + this.date.getMinutes();
    }
  }

  createWeeks(cd: Date): void {
    const date = new Date(cd.getFullYear(), cd.getMonth(), 1, 0, 0, 0);
    let day = date.getDay();
    day = (day === 0 ? 7 : day);
    date.setDate(date.getDate() - day);
    this.weeks = [];
    let end = new Date(cd.getFullYear(), cd.getMonth(), 1, 0, 0, 0);
    end.setDate(31 - end.getDate());
    end.setDate(end.getDate() + (7 - (end.getDay() === 0 ? 7 : end.getDay())));

    while (date < end) {
      this.weeks.push({
        days: Array(7).fill(0).map(() => {


          date.setDate(date.getDate() + 1);
          return {
            date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
            day: date.getDate(),
            another_month: date.getMonth() != cd.getMonth(),
            current_day: date.getMonth() === this.current_date.getMonth() && date.getFullYear() === this.current_date.getFullYear() && date.getDate() === this.current_date.getDate(),
          };
        })
      })

    };

  }

  onClick($event: any) {
      $event.stopPropagation();
  }

  onNavigatClick(value: 'next' | 'prev') {
    switch (this.view) {
      case 'week':
        this.date.setMonth(this.date.getMonth() + (value === 'next' ? 1 : -1));
        this.createWeeks(this.date);
        break;
      case 'month':
        this.date.setFullYear(this.date.getFullYear() + ((value === 'next' ? 1 : -1)));
        this.createMonths();
        break;
      case 'year':
        this.date.setFullYear(this.date.getFullYear() + ((value === 'next' ? this.NUMBER_YEAR_DISPLAY : -1 * this.NUMBER_YEAR_DISPLAY)));
        this.createYears();
        break;
    }
  }


  createYears() {
    let y = Math.ceil(this.date.getFullYear() / 5) * 5;
    let start = y - 11;
    this.years = [];
    for (let i = 0; i < 4; i++) {

      this.years.push(Array(5).fill(0).map(() => {
        start++;
        return start;
      }));
    }

  }


  createMonths() {
    this.months = [];
    let month = -1;
    for (let i = 0; i < 3; i++) {
      this.months.push(Array(4).fill(0).map(() => {
        month++;
        return month;
      }));
    }
  }

  onViewClick() {
    this.view = this.view === 'week' ? 'month' : 'year';
    this.createYears();
    this.createMonths();

  }

  onSelectDate(date: Date): void {
    this.date = date;
    if (this.type === 'date') {
      this.value = date;
    }
  }

  onSelectYear(year: number): void {
    this.date.setFullYear(year);
    this.view = 'month';
    this.createMonths();
  }
  onSelectMonth(month: number): void {
    this.date.setMonth(month);
    this.view = 'week';
    this.createWeeks(this.date);
  }


  onUpdate() {
    this.date.setHours(Number(this.value_hours));
    this.date.setMinutes(Number(this.value_minutes));
    this.value = this.date;

  }

  /*=================== ngModel ===================*/
  innerValue: any;
  private onChangedCallBack = new Array<(value: any) => void>();
  private onTouchedCallBack = new Array<() => void>();

  get value(): any {

    return this.innerValue;
  }



  set value(value: any) {
        //if (value !== this.innerValue) 
    {
      this.innerValue = value;
      this.onChangedCallBack.forEach((f: any) => {

        if (this.type === 'datetime') {
          this.date.setHours(Number(this.value_hours));
          this.date.setMinutes(Number(this.value_minutes));
        }
       
        f(this.date);
      });

    }
  }


  writeValue(value: any): void {
    this.innerValue = value;
    if (value) {
      if (typeof value === "string") {


        if (this.type === 'date') {
          const [year, month, day] = value.split('.');
          this.date = new Date(Number(year), Number(month) - 1, Number(day));
        } else {
          let a = value.split(' ');
          const [year, month, day] = a[0].split('.');
          const [hour, min] = a[1].split('.');
          this.date = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(min), 0);
        }

      } else {
        this.date = new Date(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), 0);
      }
      if (this.type === 'datetime') {

        this.value_hours = (this.date.getHours() < 10 ? '0' : '') + this.date.getHours();
        this.value_minutes = (this.date.getMinutes() < 10 ? '0' : '') + this.date.getMinutes();
      }
      this.createWeeks(this.date);
    }

  }

  registerOnChange(fn: any): void {
    this.onChangedCallBack.push(fn);
  }

  registerOnTouched(fn: any): void {

    this.onTouchedCallBack.push(fn);
  }

  /*=================== /ngModel ===================*/


}

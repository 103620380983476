import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContainerService } from '@container';
import Hospital from '@model/Hospital';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { rbSpeciality } from '@model/rbSpeciality';

@Component({
  selector: 'app-hospital',
  templateUrl: './hospital.component.html',
  styleUrls: ['./hospital.component.scss']
})
export class HospitalComponent implements OnInit, OnDestroy {
  private alive = true;
  /**
   * Медицинское учреждение
   */
  hospital: Hospital;

  specialities: rbSpeciality[];

  /**
   * Какая страница открыта
   */
  @Input() sheet: 'schedule' | 'feedback' | 'record' | 'board' | 'persons';
  constructor(private activatedRoute: ActivatedRoute, private cnt: ContainerService, private router: Router) { }

  ngOnDestroy(): void {
    this.alive = false;
    this.hospital = null;
  }
  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params) => {

        this.hospital = this.cnt.hospitals.find((h: Hospital) => {
          return h.name === params.name;
        });





        if (!this.hospital) {
          return this.router.navigate(['error', '404']);
        }

        this.updateMeta();
        this.cnt.api.send('hospital/getSpeciality', { hospital_id: this.hospital.id }, { transferState: 'hospital/getSpeciality:' + this.hospital.id }).subscribe((result: any) => {
          this.specialities = result;
        }, (error: any) => {
            this.specialities = [];
        });



      });
  }

  private updateMeta() {
    const description: string[] = [];
    description.push(this.hospital.fullTitle);
    if (this.hospital.parent) {
      description.push(this.hospital.parent.fullTitle);
    }
    description.push(this.hospital.address);
    description.push(' медицинские учреждения ' + (this.hospital.region ? this.hospital.region.title : ''));

    this.cnt.setDescription(description.join(' '));
    this.cnt.setTitle(this.hospital.fullTitle);
    this.cnt.setKeyWords('Отзывы, расписание работы, запись на приём, самозапись, сотрудники, объявления, новости');


  }



}

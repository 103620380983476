import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContainerService } from '@container';
import Hospital from '@model/Hospital';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { ActivatedRoute, Router } from '@angular/router';
import { Person } from '@model/Person';


@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss']
})
export class HospitalPersonsComponent implements OnInit, OnDestroy {
  private alive = true;
  hospital: Hospital;
  preloader = true;
  persons: Person[];
  error: any;
  type: 'board' | 'feedback';

  constructor(private activatedRoute: ActivatedRoute, private cnt: ContainerService, private cd: ChangeDetectorRef,private router:Router) { }

  ngOnDestroy(): void {
    this.hospital = null;
    this.persons = [];
    this.error = null;
    this.alive = false;
  }
  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params: { type: 'feedback' | 'board', name: string }) => {
        this.type = params.type;
        this.hospital = this.cnt.hospitals.find((h: Hospital) => {
          return h.name === params.name;
        });
        if (!this.hospital) {
          return this.router.navigate(['error', '404']);

        }

        this.cnt.api.send('hospital/getPersons', { hospital_id: this.hospital.id }, { transferState: 'hospital/getPersons:' + this.hospital.id}).subscribe((result: Person[]) => {
          this.preloader = false;
          this.persons = result;
          this.cd.detach();
          this.cd.detectChanges();
        }, (error: any) => {
          this.preloader = false;
          this.error = error;
          this.cd.detectChanges();
        })

      });
  }

}

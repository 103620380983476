import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ContainerService } from '@container';
import Hospital from '@model/Hospital';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { rbSpeciality } from '@model/rbSpeciality';
import { query } from '@angular/animations';
import { WaitInitialize } from '@helper/wait.initialize';
import rbRegion from '@model/rbRegion';


@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent implements OnInit, OnDestroy {
  private alive = true;


  readonly STEP_REGION = 1;
  readonly STEP_AREA = 2;
  readonly STEP_HOSPITAL = 3;
  readonly STEP_PERSON = 4;
  readonly STEP_TICKET = 5;
  readonly STEP_PROFILE = 6;
  readonly STEP_TALON = 7;

  step: number;
  hospital: Hospital;
  region: rbRegion;
  record: any = {
    profile: {

    }
  };

  queryParams: any;

  reserv = false;
  constructor(private activatedRoute: ActivatedRoute, public cnt: ContainerService, private router: Router, @Inject(PLATFORM_ID) public platformId) { }
  ngOnDestroy(): void {
    this.record = null;
    this.hospital = null;
    this.region = null;
    this.queryParams = null;
    this.alive = false;
  }
  ngOnInit(): void {

    this.cnt.observable_region
      .pipe(takeWhile(() => this.alive))
      .subscribe((value: any) => {
        if (!value) {
          return;
        }
        const v = value ? value.id : null;
        const c = this.region ? this.region.id : null;
        if (v !== c) {
          return this.router.navigate(['record', value.id]);
        }
      });

    // WaitInitialize(this.cnt).subscribe(() => {
    this.activatedRoute.queryParams
      .pipe(takeWhile(() => this.alive))
      .subscribe((query: any) => {
        this.queryParams = JSON.parse(JSON.stringify(query));
      });
    this.activatedRoute.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((params) => {



        if (Object.keys(params).length === 0) {
          this.step = this.STEP_REGION;
          if (this.cnt.current_region) {
            this.region = this.cnt.current_region;
            this.step = this.STEP_AREA;
          }
          this.updateMeta();
          return;
        }
        if (params.region_id) {
          const region_id = Number(params.region_id);
          this.region = this.cnt.regions.find((r: rbRegion) => {
            return r.id === region_id;
          });

          if (!this.region) {
            return this.router.navigate(['error', '404']);
          }
          if (!this.region.parent) {
            this.step = this.STEP_AREA;
          } else {
            this.step = this.STEP_HOSPITAL;
          }
          this.updateMeta();
          return;
        }
        if (params.name) {
          this.hospital = this.cnt.hospitals.find((hospital: Hospital) => {
            return hospital.name === params.name;
          });
          if (!this.hospital) {
            return this.router.navigate(['error', '404']);
          }
          this.region = this.cnt.regions.find((r: rbRegion) => {
            return r.id === this.hospital.region.id;
          });
          this.step = this.STEP_PERSON;
        }
        if (params.person_external_id) {
          if (!this.record.person && params.person_external_id === 'ticket') {
            return this.router.navigate([this.hospital.name, 'record']);
          }
          if (params.person_external_id === 'ticket') {
            this.step = this.STEP_TICKET;
          } else {
            this.step = this.STEP_PERSON;
          }
        }
        if (params.profile) {
          if (!this.record.ticket) {
            return this.router.navigate([this.hospital.name, 'record']);
          }
          this.step = this.STEP_PROFILE;
        }

        if (params.rezerv && this.record.profile.lastName) {
          this.step = this.STEP_TALON;
        }

        this.updateMeta();



        // this.cnt.setTitle('Запись на приём ');
        // if (params['reserv']) {
        //   if (Object.keys(this.record).length === 0) {
        //     return this.router.navigate(['record']);
        //   }
        //   this.reserv = true;
        //   return;
        // }
        // this.reserv = false;
        // if (this.hospital && this.hospital.name === params.name) {

        //   if (!params['person_external_id']) {
        //     this.record.person = null;
        //   }
        //   if (!params['ticket']) {
        //     this.record.ticket = null;
        //   }
        //   this.cnt.setTitle('Запись на приём ' + this.hospital.shortPrefix + ' ' + this.hospital.fullTitle + ' ' + this.hospital.address);
        //   return;
        // }
        // this.record = {};
        // if (this.queryParams && this.queryParams.lastName) {
        //   this.record.profile = this.queryParams;

        // }


        // this.hospital = this.cnt.hospitals.find((hospital: Hospital) => {
        //   return hospital.name === params.name;
        // });
        // this.cnt.setTitle('Запись на приём ' + this.hospital.fullTitle);

        if (this.cnt.user.auth) {
          this.record.profile.email = this.cnt.user.user.email;
        }
        if (this.queryParams && this.queryParams.lastName) {
          this.record.profile = this.queryParams;
        }




        this.record.hospital = {
          id: this.hospital.id
        }

      });

    // });
  }


  onSelect(type: 'person' | 'ticket' | 'success', $event: any) {

    switch (type) {
      case 'person':
        this.record.person = $event;
        this.step = this.STEP_TICKET;
        this.router.navigate([this.hospital.name, 'record', 'ticket']);
        break;
      case 'ticket':
        this.record.ticket = $event;
        this.step = this.STEP_PROFILE;
        this.router.navigate([this.hospital.name, 'record', 'ticket', 'profile']);
        break;
      case 'success':
        this.step = this.STEP_TALON;
    }
    // this.router.navigate([this.hospital.name, 'record', 'ticket']);
  }
  getSpecialityTitle(speciality: rbSpeciality[]) {
    return speciality.map(n => n.title).join(', ');
  }

  // get step(): 'specAndPerson' | 'ticket' | 'profile' | 'reserv' {
  //   if (this.reserv) {
  //     return 'reserv';
  //   }
  //   if (this.record.ticket) {
  //     return 'profile';
  //   } else if (this.record.person) {
  //     return 'ticket';
  //   }
  //   return 'specAndPerson';
  // }


  private updateMeta() {

    const description: string[] = [];

    switch (this.step) {
      case this.STEP_REGION:
        this.cnt.setTitle('Запись на прием ');
        description.push('Запись прием');
        this.cnt.setKeyWords('Запись на прием, к врачу, онлайн запись на прием, запись на прием через интернет, расписание работы, отзывы, объявления');
        break;
      case this.STEP_AREA:
        this.cnt.setTitle('Запись на прием ' + this.region.title);
        description.push('Запись прием');
        this.cnt.setKeyWords('Запись на прием, к врачу, онлайн запись на прием, запись на прием через интернет, расписание работы, отзывы, объявления');
        break;
      default:
        this.cnt.setTitle('Запись на прием ' + this.region.parent.title + ' ' + this.region.title);
        this.cnt.setKeyWords('Запись на прием, к врачу, онлайн запись на прием, запись на прием через интернет,' + this.region.parent.title + ',' + this.region.title);
        if (this.hospital) {

          this.cnt.setTitle('Запись на прием ' + this.hospital.fullTitle + ' ' + this.region.parent.title + ' ' + this.region.title);
          description.push(this.hospital.fullTitle);
          if (this.hospital.parent) {
            description.push(this.hospital.parent.fullTitle);
          }
          description.push(this.hospital.address);
          description.push(' медицинские учреждения ' + (this.hospital.region ? this.hospital.region.title : ''));


        }
        break;
    }
    this.cnt.setDescription(description.join(' '));


  }
}


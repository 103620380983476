import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ContainerService } from '@container';
import { Router } from '@angular/router';
import { UserProfile } from '@model/UserProfile';
import { UcfirstPipe } from '@pipe/ucfirst.pipe';
import { testHospital } from 'app/app-routing.matcher';
import { ThrowStmt } from '@angular/compiler';

declare var moment: any;

@Component({
  selector: 'app-record-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class RecordProfileComponent implements OnInit, OnDestroy {
  @Input() hospital: any;
  @Output() onSuccess: EventEmitter<any> = new EventEmitter();
  @Input() record: any;
  builder: any;
  preloader = false;

  police_number = false;
  profiles: UserProfile[];
  user: any;
  item_profile: any;
  error: any;
  constructor(private cnt: ContainerService, private router: Router) { }
  ngOnDestroy() {
    this.builder = null;
    this.profiles = [];
    this.user = null;
    this.item_profile = null;
    this.error = null;
  }

  ngOnInit(): void {
    // if (!this.record.profile) {
    //   this.record.profile = {
    //     // lastName: 'Петров',
    //     // firstName: 'Петр',
    //     // patrName: 'Петрович',
    //     // birthDate: '1955-11-11',
    //     // phone: '9111234567',
    //     // agreement: true,
    //   };
    // }






    this.user = this.cnt.user.user;
    if (this.cnt.user.auth) {
      if (!this.user.profiles) {
        this.cnt.api.send('profile/getProfiles').subscribe((profiles: UserProfile[]) => {
          this.user.profiles = profiles;
          this.updateProfile();

        });
      } else {
        this.updateProfile();
      }
    }

    const builder = this.cnt.get('form.builder').createForm(this.record.profile);
    this.createForm(builder);
    this.builder = builder;

  }

  updateProfile() {
    this.profiles = this.user.profiles.map(p => {
      return {
        id: p.id,
        label: UcfirstPipe.ucfirst(p.data.lastName + ' ' + p.data.firstName + ' ' + p.data.patrName),
        sublabel: [p.data.birthDate ? moment(new Date(p.data.birthDate)).format('DD.MM.YYYY') : '']
      }
    });

    this.profiles.sort((a: any, b: any) => {
      return a.title > b.title ? 1 : -1;
    })
    if (this.item_profile) {
      this.item_profile.source = this.profiles;
      this.item_profile.autocomplete = this.profiles.length > 5 ? true : false;
    }
  }

  createForm(builder) {
    const TYPE = this.cnt.get('form.builder.type');
    builder
      .action((_this) => {
        _this.button('submit', {
          label: 'Записаться',
          disabled: () => {
            return this.preloader;
          }
        })
      })
      .block((_this) => {

        _this
          .add('profile', TYPE.SelectType, {
            label: 'Профиль',
            required: false,
            source: this.profiles,
            if: (item) => {
              this.item_profile = item;
              return this.profiles && this.profiles.length > 0;
            },
            change: () => {
              if (this.record.profile.profile) {
                const id = this.record.profile.profile.id
                const find = this.user.profiles.find((p) => {
                  return p.id === id;
                });
                if (find) {
                  Object.assign(this.record.profile, {
                    lastName: UcfirstPipe.ucfirst(find.data.lastName),
                    firstName: UcfirstPipe.ucfirst(find.data.firstName),
                    patrName: UcfirstPipe.ucfirst(find.data.patrName),
                    birthDate: find.data.birthDate,
                    phone: find.data.phone,
                    email: this.user.email,
                    phoneHome: find.data.phoneHome,
                  });
                  if (this.cnt.user.auth) {
                    this.record.profile.email = this.cnt.user.user.email;
                  }
                }
              }
            },
          })

          .add('lastName', TYPE.TextType, {
            label: 'Фамилия',
            required: true,

          })

          .add('firstName', TYPE.TextType, {
            label: 'Имя',
            required: true,

          })

          .add('patrName', TYPE.TextType, {
            label: 'Отчество',
            // required: true,

          })
          .add('birthDate', TYPE.DateType, {
            label: 'Дата рождения',
            required: true,

          })

          .add('phone', TYPE.PhoneType, {
            label: 'Мобильный телефон',
            prefix: '+7',
            required: true,

          })

          .add('phoneHome', TYPE.PhoneType, {
            label: 'Домашний телефон',
            prefix: '+7',
          })
          .add('email', TYPE.TextType, {
            label: 'E-Mail',
            disabled: () => {
              return this.cnt.user.auth;
            }
          })
          .add('agreement', TYPE.CheckboxType, {
            label: 'Согласен на передачу и обработку персональных данных в соответствии с п.4 ст. 9 Федерального закона № 152-ФЗ',
            required: true,

          })
          .block(function(){


            this.add('police_number', TYPE.TextType, {
              label: 'НОМЕР полиса ОМС',
              required: true,
              hint:'Для регистрации пациента в картотеке мед. учреждения укажите НОМЕР полиса ОМС.'


            })
          },{
            if: () => {
              return this.police_number;
            }
          })
      });



  }
  onSubmit() {
    this.preloader = true;
    this.error = null;

    this.cnt.api.send('record/record', this.record).subscribe((result: any) => {
      this.preloader = false;
      // this.record.profile.external_id = result.profile.external_id;
      // this.record.code = result.code;
      this.onSuccess.next();
      this.record.html = result.html;
      this.record.token = result.token;
      // this.router.navigate(['reserv']);
    }, (error) => {
      if (Number(error.code) === 99 && this.hospital.ident) {
        this.police_number = true;
      }
      this.preloader = false;
      this.error = error;
    });
    // this.record.code = 'XOEKT';
    // this.router.navigate([ 'reserv']);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';



import { SheetComponent } from './sheet/sheet.component';
import { SheetsComponent } from './sheets.component';
import { MdElementsModule } from 'app/md-elements/md-elements.module';

let routes = [];



@NgModule({
    declarations: [
        SheetComponent,
        SheetsComponent

    ],
    imports: [
        CommonModule,
        MdElementsModule,
        RouterModule,
        RouterModule.forChild(routes)
    ],
    exports:[
        SheetComponent,
        SheetsComponent

    ]
})
export class SheetsModule { }

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sheet',
  template: ''
})
export class SheetComponent implements OnInit {

  @Input() link:string;
  @Input() active:boolean;
  @Input() label: string;
  @Input() color: string = 'default';
  constructor() { }

  ngOnInit(): void {
  }

}

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isConstructorDeclaration } from 'typescript';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    user: {
        profiles: any;//?????????
        email: string;
        id: string;
        active: boolean;
        config: any;
        login: string;
        scId: string;
        type: string;
        rules: string;
    };
    // private _config: any;
    // public get config(): any {
    //     return this._config;
    // }
    // public set config(value: any) {
    //     console.log('sssssssssssssssssss', value);
    //     this.user.config = value;
    //     this._config = value;
    // }
    constructor() {
        const _window: any = window;
        if (_window.initialization?.user) {
            this.user = _window.initialization.user;
        }
    }

    get auth() {
        return this.isAuth();
    }


    get type() {
        return this.user?.type;
    }

    isAuth() {
        return !!this.user;
    }
    getUser() {

    }
    isGranted(role: string | string[]) {
        if (!this.user?.rules) {
            return false;
        }
        const srole = Array.isArray(role) ? role : [role];
        for (const rule of srole) {
            if (this.user.rules.includes(rule)) {
                return true;
            }
        }

        return false;
    }

    get login() {
        return this.user?.login;
    }
    get email() {
        return this.user?.email;
    }
    logon(rules: any, user: any) {
        this.user = user;
    }
    logout() {
        this.user = null;
    }
}
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, OnDestroy } from '@angular/core';



@Component({
    selector: 'app-error',
    template: `
        <div class="error warn-color" *ngIf='error'>
            <div *ngIf='error.code>=0;else elseBlock'> {{ error.message || 'Неизвестная ошибка' }}</div>
            <ng-template #elseBlock>
                <div>Неизвестная ошибка</div>
            </ng-template>
            <div class='c'>
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styles: [`
        .error {
            padding:16px;
            border:1px solid red;
        }
        .c {
            font-size:small;
            font-style:italic;
        }

    `]
})
export class ErrorComponent implements OnInit, OnDestroy {
    @Input() error: {
        message: string;
        code: number;
    };
    ngOnDestroy() {
        this.error = null;
    }
    ngOnInit() {

    }
}




@NgModule({
    declarations: [
        ErrorComponent,

    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ErrorComponent


    ]
})
export class ErrorModule { }

import { Component, OnInit } from '@angular/core';
import { ContainerService } from '@container';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  constructor(private cnt:ContainerService) { }

  ngOnInit(): void {
    this.updateMeta();
  }
  updateMeta() {
    this.cnt.setTitle('');
    this.cnt.setDescription('');
    this.cnt.setKeyWords('');
  }

}

import { Component, OnInit, ViewEncapsulation, Input, AfterViewInit, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { IValidator, IValidatorType } from '../type/iValidator';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
function add0(v: number): string {
  return (v < 10 ? '0' : '') + v;
}



@Component({
  selector: 'elm-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ElmInputComponent, multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmInputComponent implements IValidator, OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  focus = false;
  _valid = true;
  get valid() {
    return this._valid;
  }
  set valid(v: boolean) {
    if (this._valid !== v) {
      this._valid = v;
      this.cd.detectChanges();
    }
  }

  @ViewChild(MatMenuTrigger) menu: MatMenuTrigger;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  @Input() placeholder: string = '';
  @Input() matAutosizeMinRows = 4;
  @Input() allowNegativeNumbers = false;
  

  @Input() prefix;
  @Input() hint;



  @Input() appearance: 'legacy' | 'outline' | 'standard' | 'fill' = 'legacy';
  @Input() icon_clear = true;

  @Input() suffix;
  @Input() validator: IValidatorType = null;
  value_date: string = '';

  @Input() type = 'text';
  m_type: string;

  @Input() mask: string = '';
  @Output('focus') evnFocus: EventEmitter<any> = new EventEmitter();
  @Output('blur') evnBlur: EventEmitter<any> = new EventEmitter();

  // @ViewChild('input', { static: false }) private input: ElementRef;

  @Input() disabled = false;
  @Input() source = null;
  @Input() clearIfNotMatch = true;
  readonly showMaskTyped = false;


  @Input() color: 'default' | 'accent' | 'primary' = 'default';


  date: Date;

  MASK_DATE = '00.00.0000';
  MASK_DATETIME = '00.00.0000 00:00';
  MASK_TIME = '00:00';
  MASK_PHONE = '(000) 000-00-00';
  MASK_GUID = 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA';

  MASK_SNILS = '000-000-000 00';
  MASK_PASSPORT = '0000-000000';




  constructor(private cd: ChangeDetectorRef) {

    // this.cd.detach();
  }
  onCalendarChangeDate(date: Date) {
    if (date) {
      if (this.menu) {
        this.menu.closeMenu();
      }
      this.date = date;
      if (this.m_type === 'date') {
        this.value = add0(date.getDate()) + '.' + add0(date.getMonth() + 1) + '.' + date.getFullYear();
      } else {
        this.value = add0(date.getDate()) + '.' + add0(date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + add0(date.getHours()) + ':' + add0(date.getMinutes()) + ':' + add0(date.getSeconds());

      }



      setTimeout(() => {
        this.updateValueDate();
      }, 1);
    }
  }

  ngOnInit() {
    this.m_type = this.type;
    switch (this.type) {
      case 'date':
        this.mask = this.MASK_DATE;
        this.type = 'text';
        break;

      case 'datetime':
        this.mask = this.MASK_DATETIME;
        this.type = 'text';
        break;
      case 'time':
        this.mask = this.MASK_TIME;
        this.type = 'text';
        break;
      case 'passport':
        this.mask = this.MASK_PASSPORT;
        this.type = 'text';
        break;

      case 'snils':
        this.mask = this.MASK_SNILS;
        this.type = 'text';
        break;

      case 'phone':
        this.mask = this.MASK_PHONE;
        this.type = 'text';
        break;
      case 'guid':
        this.mask = this.MASK_GUID;
        this.type = 'text';
        break;


    }
  }

  ngOnChanges(changes: SimpleChanges): void {


  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateValueDate();
    }, 1);


  }

  onChange($value: any) {
    alert('#23545345464534 ??????');

    /*if (typeof $value === 'string' && this.mask)
    {
      if ($event && $event.keyCode === 8) {
        let text = $value.replace(this.regexp, '');
        text = text.substring(0, text.length - 1);
        this.ngModel = text;
        return;
      }
      if (this.type === '_date') {
        const value = $value.replace(this.regexp, '');
        if ($value && $value.length === 10 && value.length===8) {
          let [day,month,year] = $value.split('.');
          this.date = new Date(Number(year), Number(month) - 1, Number(day));
        }
        this.ngModel = $value;
        return;
      }
      this.ngModel = $value.replace(this.regexp, '')
    } else */{

      if (this.m_type === 'date') {
        if ($value && $value.length === 10) {
          let [day, month, year] = $value.split('.');
          this.date = new Date(Number(year), Number(month) - 1, Number(day));
        }
        // this.ngModel = $value;
        // return;
      }

      // this.ngModel = $value;
    }

  }
  onClickCalendarContent($event: any) {
    if (this.m_type === 'datetime') {
      $event.stopPropagation();
    }
  }

  updateValueDate() {
    // if (1 == 1) return;

    if (this.focus) {
      this.value_date = null;
      this.cd.detectChanges();
      return;
    }
    if (!this.value || !this.date || (this.date.getTime() !== this.date.getTime())) {
      this.value_date = null;
      this.cd.detectChanges();
      return;
    }
    const months = ['янв', 'фев', 'март', 'апр', 'май', 'июня', 'июля', 'авг', 'сен', 'окт', 'ноя', 'дек'];
    const day = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ']

    if (this.m_type === 'date') {
      this.value_date = day[this.date.getDay()] + ', ' + ((this.date.getDate() < 10 ? '0' : '') + this.date.getDate()) + ' ' + months[this.date.getMonth()] + ' ' + this.date.getFullYear();
      this.cd.detectChanges();
      return;
    }


    if (this.m_type === 'datetime') {
      this.value_date = day[this.date.getDay()] + ', ' + ((this.date.getDate() < 10 ? '0' : '') + this.date.getDate()) + ' ' + months[this.date.getMonth()] + ' ' + this.date.getFullYear() + ' ' + add0(this.date.getHours()) + ':' + add0(this.date.getMinutes());
      this.cd.detectChanges();
      return;
    }
    this.cd.detectChanges();
    this.value_date = null;
    return;
  }

  onFocus($event: any, focus: boolean) {
    this.focus = focus;
    if (focus) {
      this.evnFocus.emit(true);

    } else {
      this.evnBlur.emit(true);
    }
    this.updateValueDate();
  }

  getValue(): string {

    return this.value;
  }

  onTest(e: any) {
    console.info('E>>', e);
  }


  /*=================== ngModel ===================*/
  innerValue: any;
  private onChangedCallBack = new Array<(value: any) => void>();
  private onTouchedCallBack = new Array<() => void>();

  get value(): any {

    return this.innerValue;
  }

  set value(value: any) {
    if (value !== this.innerValue) {

      this.valid = true;
      this.innerValue = value;
      this.onChangedCallBack.forEach((f: any) => {


        if (this.m_type === 'date') {
          if (!value) {
            return f(null);
          }

          if (typeof value === 'string') {
            let [day, month, year] = value.split('.');
            this.date = new Date(Number(year), Number(month) - 1, Number(day));
          }


          if (this.date.getTime() === this.date.getTime()) {
            f(this.date.getFullYear() + '-' + add0(this.date.getMonth() + 1) + '-' + add0(this.date.getDate()));
          } else {
            this.date = new Date();
            f(null);
          }
          return;
        }


        if (this.m_type === 'datetime') {
          if (!value) {
            return f(null);
          }
          if (typeof value === 'string') {
            const a = value.split(' ');
            let [day, month, year] = a[0].split('.');
            let [hour, min, sec] = a[1] ? a[1].split(':') : [];

            this.date = new Date(Number(year), Number(month) - 1, Number(day), Number(hour), Number(min), Number(sec || 0));

          }

          if (this.date.getTime() === this.date.getTime()) {
            f(this.date.getFullYear() + '-' + add0(this.date.getMonth() + 1) + '-' + add0(this.date.getDate()) + 'T' + add0(this.date.getHours()) + ':' + add0(this.date.getMinutes()) + ':' + add0(this.date.getSeconds()));
          } else {
            this.date = new Date();
            f(null);
          }


          return;
        }

        f(value)
      });


    }
  }


  writeValue(value: any): void {
    this.innerValue = value;
    if (value) {
      if (this.m_type === 'date') {
        this.date = new Date(value);
        this.innerValue = add0(this.date.getDate()) + '.' + add0(this.date.getMonth() + 1) + '.' + this.date.getFullYear();
      }
      else if (this.m_type === 'datetime') {
        this.date = new Date(value);
        this.innerValue = add0(this.date.getDate()) + '.' + add0(this.date.getMonth() + 1) + '.' + this.date.getFullYear() + ' ' + add0(this.date.getHours()) + ':' + add0(this.date.getMinutes());
      }

    }
    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChangedCallBack.push(fn);
  }

  registerOnTouched(fn: any): void {

    this.onTouchedCallBack.push(fn);
  }

  /*=================== /ngModel ===================*/
}

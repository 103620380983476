import { Component, OnInit, Input, QueryList, ViewChildren, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
// import { ElmFormComponent } from '../md-elements/form/form';
import { ElmInputComponent } from '../md-elements/input/input.component';
import { Validator, IValidatorType } from '../md-elements/type/iValidator';
import { ElmSelectComponent } from '../md-elements/select/select.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit, OnDestroy {



  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onSubmit: EventEmitter<void> = new EventEmitter();
  @Input() builder: any;

  @Input() preloader_message:string;
  @Input() preloader = false;
  @Input() disabled = false;

  @ViewChildren(ElmInputComponent, {}) inputs: QueryList<ElmInputComponent>;
  @ViewChildren(ElmSelectComponent, {}) selects: QueryList<ElmSelectComponent>;
  constructor(private cd: ChangeDetectorRef, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  getValue(item: any, collection: any) {
    if (collection) {
      return collection[item.name];
    }
    return item.value;
  }

  setValue(item: any, $event: any, collection: any) {
    if (collection) {
      collection[item.name] = $event;
      return;
    }
    item.value = $event;
  }
  ngOnDestroy(): void {
    this.builder = null;
    this.inputs = null;
    this.selects = null;
  }

  // setValue(item: any, item_collection: any, index: number) {
  //   if (!this.builder.values[item.name][index][item_collection.name]) {
  //     this.builder.values[item.name][index][item_collection.name] = null;
  //     item_collection.value = this.builder.values[item.name][index][item_collection.name];
  //   }
  // }
  onAdd(item: any) {
    this.builder.values[item.name].push({});
  }
  range(a: any): number[] {
    return Array(a.length).fill(0).map((x: any, i: any) => i);
  }
  onButtonClick(item: any, builder: any,index:number) {
    if (item.click) {
      if (!item.click(item, builder,index)) {
        return;
      }
    }
    if (item.name === 'submit') {
      if (!this.isValid()) {
        return;
      }
      this.onSubmit.emit();
    }
    if (item.name === 'cancel') {
      this.onCancel.emit();
    }
  }

  getCss(style) {
    return this.sanitizer.bypassSecurityTrustHtml('<style type="text/css">' + style + '</style>');
  }

  isValid(): boolean {
    this.cd.detectChanges();
    let elements = [];
    elements = elements.concat(this.inputs.toArray(), this.selects.toArray());
    let valid = true;
    elements.forEach((input: Validator) => {
      if (input.validator) {
        if (this.__testValid(input.getValue(), input.validator)) {
          input.valid = true;
        } else {
          input.valid = false;
          valid = false;
        }
      }
    });
    elements = [];
    return valid;
  }


  private __testValid(value: any, validator: IValidatorType): boolean {
    if (!validator) {
      return true;
    }
    if (validator.required) {
      if (!value) {
        return false;
      }
      if (typeof value === 'object') {
        if (Object.keys(value).length === 0) {
          return false;
        }
      } else if ((!value || value === '' || value === undefined || (Array.isArray(value) && value.length === 0))) {
        return false;
      }
    }
    if (validator.match) {
      const regex = new RegExp(validator.match);
      const test_value = Array.isArray(value) ? value.join('') : value || '';
      if (!regex.test(test_value)) {
        return false;
      }
    }
    if (validator.active) {
      if (Array.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
          if (!value[i].active) {
            return false;
          }
        }
      } else {
        if (value && !value.active) {
          return false;
        }
      }
    }
    return true;
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'elm-spinner',
  template: '<table border="0"><tr><td><mat-spinner [diameter]="diameter" [color]="color"></mat-spinner></td><td><ng-content></ng-content></td></tr></table>',
  styles: [`
  
  `]
})
export class ElmSpinnerComponent implements OnInit {

  @Input() diameter = 30;
  @Input() color='primary';
  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef, Output, EventEmitter, ViewEncapsulation, ContentChild, TemplateRef, Sanitizer, AfterContentInit, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { tick } from '@angular/core/testing';
import { Router } from '@angular/router';

@Component({
  selector: 'elm-button',
  //  styles:['.mat-icon {margin-right:4px;} button,a {line-height:normal;padding:8px 8px;}'],
  // styles: [''],
  styleUrls: ['./button.component.scss'],
  templateUrl: './button.component.html',
  /*template: `
    <ng-template #content><ng-content></ng-content></ng-template>
    <button *ngIf='!href && !link' #button mat-button
    [ngStyle]="{display:display,textAlign:textAlign}"
        [class.mat-raised-button]="type==='raised'"
        [class.mat-stroked-button]="type==='stroked'"
        [class.mat-icon-button]="type==='icon'"
        [class.mat-flat-button]="type==='flat'"
        [class.mat-fab]="type==='fab'"
        [class.mat-mini-fab]="type==='mini-fab'"
        [color]="color"
        [disabled]="disabled">
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
      <ng-container *ngTemplateOutlet="content"></ng-container>
      <!-- <ng-content></ng-content> -->
    </button>
    <a *ngIf='href && !link' #button mat-button
        href="{{ href }}"
       [ngStyle]="{display:display,textAlign:textAlign}"
        target="{{ target }}"
        [class.mat-raised-button]="type==='raised'"
        [class.mat-stroked-button]="type==='stroked'"
        [class.mat-icon-button]="type==='icon'"
        [class.mat-flat-button]="type==='flat'"
        [class.mat-fab]="type==='fab'"
        [class.mat-mini-fab]="type==='mini-fab'"
        [color]="color"
        [disabled]="disabled">
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <a *ngIf='!href && link' #button mat-button
        [routerLink]="link"
        [ngStyle]="{display:display,textAlign:textAlign}"
        [class.mat-raised-button]="type==='raised'"
        [class.mat-stroked-button]="type==='stroked'"
        [class.mat-icon-button]="type==='icon'"
        [class.mat-flat-button]="type==='flat'"
        [class.mat-fab]="type==='fab'"
        [class.mat-mini-fab]="type==='mini-fab'"
        [color]="color"
        [disabled]="disabled">
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>

  `,*/

})
export class ElmButtonComponent implements OnInit, OnChanges {


  @Input() disabled = false;
  @Input() icon: string;
  @Input() link: string;
  @Input() href: string;
  @Input() display = 'inline-block';
  @Input() textAlign = 'center';
  @Input() target = '_blank';
  @Input() color: 'default' | 'accent' | 'primary' = 'default';
  @Input() type: 'default' | 'raised' | 'stroked' | 'flat' | 'icon' | 'fab' | 'mini-fab' = 'default';

  classList = '';

  // @Input() multi = false;


  constructor(private sanitizer: DomSanitizer, private router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {
  }

  onClick() {
    if (this.link) {
      this.router.navigateByUrl(this.link);
    }
  }

  ngOnInit() {
  }

}

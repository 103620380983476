import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormBuilderModule } from './form-builder/form-builder.module';
import { MdElementsModule } from './md-elements/md-elements.module';
import { HospitalFeedbackComponent } from './pages/hospital/feedback/feedback.component';
import { HospitalComponent } from './pages/hospital/hospital.component';
import { HospitalScheduleComponent } from './pages/hospital/schedule/schedule.component';
import { HospitalsComponent } from './pages/hospitals/hospitals.component';
import { RecordComponent } from './pages/record/record.component';
import { RecordSpecAndPersonComponent } from './pages/record/spec-and-person/spec-and-person.component';
import { RecordTicketComponent } from './pages/record/ticket/ticket.component';
import { AccessDeniedComponent } from './pages/security/access-denied/access-denied.component';
import { LogonComponent } from './pages/security/logon/logon.component';
import { RegisterComponent } from './pages/security/register/register.component';

import { RecordProfileComponent } from './pages/record/profile/profile.component';

import { AnalyzesComponent } from './pages/analyzes/analyzes.component';
import { HospitalMenuComponent } from './pages/hospital/menu/hospital.menu.component';
import { HospitalPersonsComponent } from './pages/hospital/persons/persons.component';
import { PreRecordComponent } from './pages/pre-record/pre-record.component';
import { RestorePasswordComponent } from './pages/security/restore-password/restore-password.component';
import { WrapComponent } from './pages/wrap/wrap.component';

import { PipeModule } from '@pipe/pipe.module';

import { MatButtonModule } from '@angular/material/button';


// import { PaidPersonComponent } from './pages/paid/person/person.component';
// import { PaidDiagnosticComponent } from './pages/paid/diagnostic/diagnostic.component';
import { AdsComponent } from '@helper/ads/ads.component';
import { ErrorModule } from '@helper/component/error.module';
import { SheetsModule } from '@helper/sheets/sheets.module';
import { PageErrorComponent } from './pages/error/error.component';
// import { PaidDiagnosticRecordComponent } from './pages/paid/diagnostic/record/record.component';
import { QAComponent } from './pages/aq/aq.component';
import { ContactComponent } from './pages/contact/contact.component';
// import { CznpvComponent } from './pages/cznpv/cznpv.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
// import { DrugsComponent } from './pages/drugs/drugs.component';
// import { NoticeComponent } from './notice/notice.component';
import { PageADSComponent } from './pages/ads/ads.component';
// import { FormComponent } from './pages/form/form.component';

import { n2brPipe } from '@pipe/n2br.pipe';
import { ShortNamePipe } from '@pipe/shortName.pipe';
// import { MessengerComponent } from './messenger/messenger.component';
import { PageTitleModule } from './page-title.module';
import { TestCancelComponent } from './pages/test-cancel/test-cancel.component';

declare const moment;
moment.locale('ru');

@NgModule({
  declarations: [

    AppComponent,
    LogonComponent,
    RegisterComponent,
    AccessDeniedComponent,
    HospitalsComponent,
    HospitalComponent,
    HospitalMenuComponent,
    HospitalFeedbackComponent,
    HospitalScheduleComponent,
    RecordComponent,
    RecordSpecAndPersonComponent,
    RecordTicketComponent,
    RecordProfileComponent,
    WrapComponent,
    PreRecordComponent,
    RestorePasswordComponent,
    // PaidPersonComponent,
    // PaidDiagnosticComponent,
    // PaidDiagnosticRecordComponent,
    AnalyzesComponent,
    HospitalPersonsComponent,
    // TestComponent,
    // CancelComponent,
    TestCancelComponent,
    HomepageComponent,
    PageErrorComponent,
    // CznpvComponent,
    AdsComponent,
    ContactComponent,
    QAComponent,
    // DrugsComponent,
    // FormComponent,
    // NoticeComponent,
    PageADSComponent,
    // MessengerComponent,
    n2brPipe,
    ShortNamePipe


  ],
  imports: [
    // MatRippleModule,
    PipeModule,
    BrowserModule.withServerTransition({ appId: 'onlinelpu' }),
    PageTitleModule,
    AppRoutingModule,
    // MatIconModule,
    HttpClientModule,
    MatButtonModule,
    SheetsModule,
    ErrorModule,
    BrowserAnimationsModule,
    MdElementsModule,

    FormBuilderModule,
    FormsModule,
    BrowserTransferStateModule,



    // MatTabsModule,
    // MatExpansionModule,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

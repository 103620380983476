import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContainerService } from '@container';

@Component({
  selector: 'app-pre-record',
  templateUrl: './pre-record.component.html',
  styleUrls: ['./pre-record.component.scss']
})
export class PreRecordComponent implements OnInit {
  constructor(public cnt: ContainerService, private router: Router) { }

  ngOnInit(): void {
    this.updateMeta();
    this.router.navigate(['record']);
    // if (this.cnt.current_region && this.cnt.current_region.id !== 13) {
    //   this.router.navigate(['record']);
    //   return;
    // }
  }

  private updateMeta() {
    this.cnt.setDescription('Запись на прием, запись по направлению, платный прием врачей');
    this.cnt.setTitle('Запись на прием');
    this.cnt.setKeyWords('Запись на прием, запись на прием через интернет, онлайн запись на прием, платный прием врачей, запись на платный прием, запись на диагностику, запись по направлению');
  }

}

import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ContainerService } from '@container';
import rbRegion from '@model/rbRegion';
import Hospital from '@model/Hospital';

@Component({
  selector: 'app-analyzes',
  templateUrl: './analyzes.component.html',
  styleUrls: ['./analyzes.component.scss']
})
export class AnalyzesComponent implements OnInit,OnDestroy, AfterViewInit {

  profile: {
    firstName: string;
    lastName: string;
    patrName: string;
    birthDate: string;
    agreement: boolean;
    region: any;
    email: string;
    area: {
      id: number;
      label: string;
    };
    snils: string;
    passport: string;
    police: string;
    hospital: {
      value: number;
      label: string;
    }
  } = {} as any;
  builder: any;
  @ViewChild('template', { static: false }) template: TemplateRef<any>;
  // builder_document: any;
  hospitals: any = [];
  item_hospital: any;
  preloader = false;
  // show_form = false;
  preloader_analyzes = false;
  error: any;
  select_hospital_dialog = false;
  // error_email = false;

  // check_pacient: {
  //   snils: boolean;
  //   passport: boolean;
  //   police: boolean;
  // } = null;

  total_analyze :number= null;
  success_analyze = false;
  // error_analyze = null;
  // error_email_empty = null;
  // error_status = null;

  constructor(private cnt: ContainerService,private cd:ChangeDetectorRef) {



    this.profile.region = 'г. Санкт-Петерубург';
    this.builder = this.cnt.get('form.builder').createForm(this.profile);
    // this.builder_document = this.cnt.get('form.builder').createForm(this.profile);

    // this.createDocument();
  }
  ngOnDestroy() {
    this.profile = null;
    this.builder = null;
    this.hospitals = [];
    this.item_hospital = null;
    this.error = null;
  }
  ngAfterViewInit(): void {
    this.createForm();
  }

  ngOnInit(): void {
    this.updateMeta();
  }

  onChangeRegion() {
    this.profile.hospital = null;
    this.item_hospital.source = this.cnt.hospitals.filter((hospital: Hospital) => {
      return hospital.availableRecord && hospital.region && this.profile.area && hospital.region.id === this.profile.area.id;
    }).map((hospital: Hospital) => {
      return {
        value: hospital.id,
        label: hospital.fullTitle,
        sublabel: [(hospital.parent ? hospital.parent.fullTitle : null)],
      }
    });
  }


  createForm() {
    const TYPE = this.cnt.get('form.builder.type');
    const regions = this.cnt.regions.filter((region: rbRegion) => {
      return region.parent && region.parent.id === 13;
    }).map((region: rbRegion) => {
      return {
        id: region.id,
        label: region.title,
      }
    });;
    const builder = this.builder;

    builder
      .action((_this: any) => {
        _this.button('submit', {
          label: 'Получить анализы',

        })
      })
      .block((_this: any) => {
        _this.flex((_this: any) => {

          _this.add('lastName', TYPE.TextType, {
            label: 'Фамилия',
            required: true,

          })

            .add('firstName', TYPE.TextType, {
              label: 'Имя',
              required: true,

            })

            .add('patrName', TYPE.TextType, {
              label: 'Отчество',
              required: true,

            })
            .add('birthDate', TYPE.DateType, {
              label: 'Дата рождения',
              required: true,

            });

        })


          .add('email', TYPE.TextType, {
            label: 'E-Mail',
            hint: 'Адрес электронной почты зарегистрированный в картотеке медицинского учреждения',
            required: true,

          })

          .html(() => { return '<br><h4>Медицинское учреждение.</h4><div class="primary-color">Необходимо выбрать медицинское учреждение, в котором у Вас есть прикрепление:</div><br>' }, {
            if: () => {
              return !this.profile.hospital;
            }
          })
          // .flex((_this) => {
          //   _this.add('region', TYPE.TextType, {
          //     label: 'Регион',
          //     disabled: () => {
          //       return true;
          //     },
          //     hint: 'Сервис доступен только для г. Санкт-Петербург',


          //   }).add('area', TYPE.SelectType, {
          //     source: regions,
          //     label: 'Район',
          //     required: true,

          //     change: () => {
          //       this.onChangeRegion();
          //     },
          //   })
          //     .flex((_this) => {
          //       _this.add('hospital', TYPE.SelectType, {
          //         label: 'Медицинское учреждение',
          //         required: true,
          //         autocomplete: true,
          //         disabled: (item) => {
          //           this.item_hospital = item;
          //           return this.preloader
          //         },

          //         source: this.hospitals,

          //       });
          //     }, {
          //       style: {
          //         flex: 2,
          //       }
          //     });
          // })


        .add('template', 'template', {
          template: this.template,
        })
        .add('agreement', TYPE.CheckboxType, {
            label: 'Согласен на передачу и обработку персональных данных в соответствии с п.4 ст. 9 Федерального закона № 152-ФЗ',
            required: true,

          })
      });
  }


  onGetAnalyzes() {

    this.success_analyze = false;
    // this.error_analyze = null;
    // this.error_status = null;
    this.error = null;
    this.preloader = true;

    this.cnt.api.send('analyzes/getAnalyze', {

      profile: {
        lastName: this.profile.lastName,
        firstName: this.profile.firstName,
        patrName: this.profile.patrName,
        email: this.profile.email,
        birthDate: this.profile.birthDate,
      },
      hospital_id: this.profile.hospital.value,

    }).subscribe((result: any) => {
      this.preloader = false;




      this.total_analyze = result.total;
      this.success_analyze = true;

    }, (error: any) => {

      this.preloader = false;
      this.error = error;
    });
  }


  private updateMeta() {
    this.cnt.setDescription('Запрос результатов анализов');
    this.cnt.setTitle('Получить результаты анализов');
    this.cnt.setKeyWords('анализы, результат, на почту, скачать, как получить');
  }

  onSelectHospital(hospital: any) {
    this.select_hospital_dialog = false;
    this.profile.hospital = {
      value: hospital.id,
      label: hospital.shortTitle,
      sublabel: [null, hospital.address]

    } as any;

  }
}

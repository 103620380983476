import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { LogonComponent } from './pages/security/logon/logon.component';
import { RegisterComponent } from './pages/security/register/register.component';
import { AccessDeniedComponent } from './pages/security/access-denied/access-denied.component';
import { HospitalsComponent } from './pages/hospitals/hospitals.component';
import { HospitalComponent } from './pages/hospital/hospital.component';
import { HospitalScheduleComponent } from './pages/hospital/schedule/schedule.component';
import { HospitalFeedbackComponent } from './pages/hospital/feedback/feedback.component';
import { RecordComponent } from './pages/record/record.component';
import { PreRecordComponent } from './pages/pre-record/pre-record.component';


import { environment } from './../environments/environment';
import { RestorePasswordComponent } from './pages/security/restore-password/restore-password.component';
import { AnalyzesComponent } from './pages/analyzes/analyzes.component';
import { HospitalPersonsComponent } from './pages/hospital/persons/persons.component';
import { AuthGuardService } from './service/auth.guard.service';
// import { PaidPersonComponent } from './pages/paid/person/person.component';
// import { PaidDiagnosticComponent } from './pages/paid/diagnostic/diagnostic.component';
import { PageErrorComponent } from './pages/error/error.component';
// import { PaidDiagnosticRecordComponent } from './pages/paid/diagnostic/record/record.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { testHospital, testRecordHospital, testFeedbackHospital, testScheduleHospital, testPersonsHospital, finalMatcher } from './app-routing.matcher';
// import { CznpvComponent } from './pages/cznpv/cznpv.component';
import { ContactComponent } from './pages/contact/contact.component';
import { QAComponent } from './pages/aq/aq.component';
// import { DrugsComponent } from './pages/drugs/drugs.component';
// import { FormComponent } from './pages/form/form.component';
// import { NoticeComponent } from './notice/notice.component';
import { PageADSComponent } from './pages/ads/ads.component';
// import { MessengerComponent } from './messenger/messenger.component';
import { TestCancelComponent } from './pages/test-cancel/test-cancel.component';




export const routes: Routes = [
  { path: '', component: HomepageComponent, },
  { path: 'logon', component: LogonComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'aq', component: QAComponent },
  // { path: 'notice', component: NoticeComponent },

  // { path: 'cznpv', component: CznpvComponent },

  // { path: 'drugs', component: DrugsComponent },
  // { path: 'drugs/:name', component: DrugsComponent },


  // { component: PaidPersonComponent, matcher: testCommercial },
  // { path: 'diagnostic', component: PaidDiagnosticComponent },

  // { path: 'diagnostic/:id', component: PaidDiagnosticComponent },
  // { path: 'diagnostic/:id/limit/:limit', component: PaidDiagnosticComponent },
  // { path: 'diagnostic/record/:diag_id/:hospital_id/:child_ids', component: PaidDiagnosticRecordComponent },
  // { path: 'diagnostic/record/:diag_id/:hospital_id', component: PaidDiagnosticRecordComponent },


  { path: 'analyzes', component: AnalyzesComponent },
  { component: HospitalComponent, matcher: testHospital },
  { component: RecordComponent, matcher: testRecordHospital, canActivate: [] },
  { component: HospitalFeedbackComponent, matcher: testFeedbackHospital, canActivate: [] },
  { component: HospitalScheduleComponent, matcher: testScheduleHospital, canActivate: [] },
  { component: HospitalPersonsComponent, matcher: testPersonsHospital, canActivate: [] },

  { path: 'register', component: RegisterComponent },
  { path: 'restorePassword', component: RestorePasswordComponent },
  { path: 'record/menu', component: PreRecordComponent, canActivate: [] },
  { path: 'record', component: RecordComponent, canActivate: [] },
  { path: 'record/:region_id', component: RecordComponent, canActivate: [] },
  { path: 'hospitals', component: HospitalsComponent, canActivate: [] },
  { path: 'hospitals/:region_name', component: HospitalsComponent, canActivate: [] },
  { path: 'hospitals/:region_name/:type', component: HospitalsComponent, canActivate: [] },
  { path: 'accessDenied', component: AccessDeniedComponent },




  // { path: 'form/:uuid', component: FormComponent, canActivate: [AuthGuardService] },



  { path: 'tc', component: TestCancelComponent },
  { path: 'tc/:code/:lastName', component: TestCancelComponent },


  { path: 'ads', component: PageADSComponent },

  { path: 'error/:code', component: PageErrorComponent },

  { path: 'test/:code/:lastName', redirectTo: 'tc' },
  { path: 'cancel/:code/:lastName', redirectTo: 'tc' },
  { path: 'test', redirectTo: 'tc' },
  { path: 'cancel',redirectTo: 'tc' },


//  { matcher: finalMatcher, component: PageErrorComponent }



];

if (!environment.server) {
  routes.push({ path: 'manager', loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule), canActivate: [], data: { granted: ['ROLE_ADMIN'] } });
  routes.push({ path: 'office', loadChildren: () => import('./office/office.module').then(m => m.OfficeModule), canActivate: [] });
  // routes.push({ path: 'chat', component: MessengerComponent });

  routes.push({ matcher: finalMatcher, component: PageErrorComponent });

}



@NgModule({
  imports: [RouterModule.forRoot(routes,{/*initialNavigation: 'enabled'*/})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ContainerService } from '@container';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  constructor(public cnt:ContainerService) { }

  ngOnInit(): void {
    this.updateMeta();
  }

  updateMeta() {
    this.cnt.setTitle('Запись на прием к врачу').setDescription('Запись на прием к врачу').setKeyWords('запись на прием, самозапись, платный прием, запись к врачу по направлению, результаты анализов, личный кабинет, диагностика');
  }

}
